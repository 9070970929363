import React, {useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import applianceApi from '../../api/applianceApi'
import ConnectToAppliance from "../add-new-appliance/ConnectToAppliance"
import {useAlert} from 'react-alert'
import Cookies from 'js-cookie'
import {useSelector} from "react-redux";
import * as _ from 'lodash'
import {useAuthCheck} from "../../hooks/login-hook";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import AddPermissionModal from "./AddPermissionModal";
import ShowPermissionModal from "./ShowPermissionModal";
import ShowLogs from "./ShowLogs";
import * as moment from "moment";
import permissionApi from "../../api/permissionApi";
import Echo from "laravel-echo";
import SetTypePopup from "./SetTypePopup";
import AutoCommandItem from "../auto-command/AutoCommandItem";
import ApplianceCommand from "./ApplianceCommand";
import autoCommandApi from "../../api/autoCommandApi";

window.Pusher = require("pusher-js");

const token = Cookies.get('access_token')
const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: "ap1",
  forceTLS: true,
  auth: {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  },
  authEndpoint: process.env.REACT_APP_API_URL + '/api/broadcasting/auth'
});

export default function ApplianceInfomation(props) {
  let user = useSelector((state) => state.userLog.user)
  const {uid} = useParams()
  const [appliance, setAppliance] = useState({})
  const [permission, setPermission] = useState({})
  const [pinCode, setPinCode] = useState('')
  const [error, setError] = useState('')
  const [switchStatus, setSwitchStatus] = useState(0)
  const [requestUuid, setRequestUuid] = useState('')
  const [openConnectionModal, setOpenConnectionModal] = useState(false)
  const [openWaitModal, setOpenWaitModal] = useState(false)
  const [quickLogs, setQuickLogs] = useState([])
  const [connectionInfo, setConnectionInfo] = useState({})
  const [isEditingName, setIsEditingName] = useState(false)
  const [isSendingChangeStatus, setIsSendingChangeStatus] = useState(false)
  const [openDeleteConfirmationPopup, setOpenDeleteConfirmationPopup] = useState(false)
  const [openSetTypePopup, setOpenSetTypePopup] = useState(false)
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false)
  const [openShowPermissionModal, setOpenShowPermissionModal] = useState(false)
  const [openShowLogs, setOpenShowLogs] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('')
  const [autoCommands, setAutoCommands] = useState([])
  const [countdownAutoCommand, setCountdownAutoCommand] = useState({})
  const [channelName, setChannelName] = useState('');
  const [networkMode, setNetworkMode] = useState('');
  const [networkIP, setNetworkIP] = useState('');
  const [appliancePassword, setAppliancePassword] = useState('');
  const inputNameRef = useRef();
  const alert = useAlert();
  const authCheck = useAuthCheck()
  const modeLAN = 'LAN';
  const modeInternet = 'INTERNET';

  useEffect(async () => {
    try {
      if (_.isEmpty(user)) {
        user = await authCheck()
      }
      if (user) {
        let pin = Cookies.get('pin-' + user.phone_number + '-' + uid)
        if (!_.isEmpty(pin)) {
          try {
            pin = JSON.parse(pin)
            setPinCode(pin.code)
          } catch (e) {
            alert.error('Vui lòng đồng bộ lại mã PIN.')
          }
        }
      }
      const response = await applianceApi.getDetail(uid)

      if (response.success) {
        setAppliance(response.data.appliance)
        setPermission(response.data.permission)
        if (response.data.appliance.type === 3) {
          setSwitchStatus(response.data.appliance.switch_status)
          const autoCommandResponse = await autoCommandApi.getCommands(uid)
          setAutoCommands(autoCommandResponse.data.filter(command => command.type === 1))
          setCountdownAutoCommand(autoCommandResponse.data.find(command => command.type === 2))
        }
        setChannelName( 'appliance-detail-page.' + response.data.appliance.uid.toLowerCase())
        if (response.data.permission.role === 1) {
          try {
            // Lấy log từ db
            const response = await permissionApi.getLogs(uid, 1, 1)
            if (response.success) {
              setQuickLogs(response.data)
            } else {
              alert.error(response.message)
            }
          } catch (e) {
            alert.error(e.toString() + e.response.data.message)
          }
        }
      } else {
        setError(response.message)
      }
    } catch (e) {
      setError(e.response.data.message)
    }
  }, [])

  useEffect(() => {
    if (isEditingName) {
      inputNameRef.current.focus();
    }
  }, [isEditingName])

  useEffect(async () => {
    if (channelName === '') return
    try {
      // Kết nối đến Boardcast
      var channel = await echo.private(channelName)
      channel.listen("ApplianceStatusUpdated", function (message) {
        // console.log('éc éc')
        // setQuickLogs([log])
        // setBackgroundColor('#FFFF00')
        // setTimeout(() => setBackgroundColor(''), 5000)
        setSwitchStatus(message['switch_status'])
        setRequestUuid(message['request_uuid'])
      });
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    }
    return () => {
      if (channelName) {
        echo.leave(channelName)
      }
    }
  }, [channelName])

  useEffect(() => {
    return () => {
      if (channelName === '') return
      echo.leave(channelName)
    }
  }, [channelName])

  // Kiểm tra chế độ mạng mở khóa (networkMode)
  useEffect(() => {
    const networkModeFromStorage = localStorage.getItem(`network_${uid}_mode`)
    const networkIPFromStorage = localStorage.getItem(`network_${uid}_ip`)
    const appliancePasswordFromStorage = Cookies.get(`appliance_password_${uid}`)
    let mode = modeInternet
    let ip = ''
    let appliancePassword = ''
    if (!_.isEmpty(networkModeFromStorage)) {
      mode = networkModeFromStorage === modeLAN ? modeLAN : modeInternet
    }
    if (!_.isEmpty(networkIPFromStorage)) {
      ip = networkIPFromStorage
    }
    if (!_.isEmpty(appliancePasswordFromStorage)) {
      appliancePassword = appliancePasswordFromStorage
    }
    setNetworkMode(mode)
    setNetworkIP(ip)
    setAppliancePassword(appliancePassword)
  }, [])

  const handleLeaveChannel = () => {
    echo.leave('private-appliance-detail-page.' + (appliance.serial ?? '').toLowerCase())
  }

  const saveNewName = async (e) => {
    if (e.target.value.trim() !== appliance.name) {
      try {
        const response = await applianceApi.setName(uid, e.target.value)
        if (response.success) {
          alert.success(response.message)
          setAppliance({...appliance, name: e.target.value})
        } else {
          alert.error(response.message)
        }
      } catch (e) {
        alert.error(e.toString() + e.response.data.message)
      }
    }
    setIsEditingName(false)
  }

  const handleChangeStatus = async (e) => {
    if (isSendingChangeStatus) {
      return
    }
    try {
      setIsSendingChangeStatus(true)
      const response = await applianceApi.changeStatus(uid, appliance.status === 1 ? 0 : 1)
      if (response.success) {
        alert.success(response.message)
        setAppliance({...appliance, status: appliance.status === 1 ? 0 : 1})
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    } finally {
      setIsSendingChangeStatus(false)
    }
  }

  const refreshAutoCommand = (autoCommandId) => {
    const tmpAutoCommands = {...autoCommands}
    delete tmpAutoCommands[autoCommandId]
    setAutoCommands(tmpAutoCommands)
  }

  const handleChangeNetworkIP = (event) => {
    setNetworkIP(event.target.value)
  }

  const handleChangeAppliancePassword = (event) => {
    setAppliancePassword(event.target.value)
  }

  const onSwitchNetworkMode = () => {
    if (networkMode === modeInternet) {
      setNetworkMode(modeLAN)
      localStorage.setItem(`network_${uid}_mode`, modeLAN)
      localStorage.setItem(`network_${uid}_ip`, networkIP)
      Cookies.set(`appliance_password_${uid}`, appliancePassword, { expires: 30, secure: true })
    }
    if (networkMode === modeLAN) {
      setNetworkMode(modeInternet)
      localStorage.setItem(`network_${uid}_mode`, modeInternet)
    }
    alert.success('Chuyển chế độ thành công')
  }

  return (
    <>
      <div className="container mb-5" style={{maxWidth: '500px'}}>
        <div className={'d-flex justify-content-between'}>
          <Link to="/appliances" style={{textDecoration: 'none'}}>
            <i className="fas fa-angle-double-left"/> Thiết bị của tôi
          </Link>
          <div className="form-check form-switch form-check-inline">
            <label className="form-check-label" htmlFor="flexSwitchCheckChecked"><small>Chế
              độ {networkMode === modeLAN ? modeLAN : modeInternet}</small></label>
            <input readOnly={true} className="form-check-input" type="checkbox" role="switch"
                   id="flexSwitchCheckChecked" checked={networkMode === modeInternet}
                   onClick={networkMode === modeLAN ? onSwitchNetworkMode : null}
                   data-bs-toggle="collapse"
                   href={networkMode === modeInternet ? `#collapse_network_mode` : ''}
                   role="button"
                   aria-expanded="false"
                   aria-controls={networkMode === modeInternet ? `collapse_network_mode` : ''}
            />
          </div>
        </div>
        <div
          className="collapse shadow-sm mt-2"
          id={`collapse_network_mode`}
        >
          <div className="card card-body py-3 shadow">
            <div className="row">
              <div><small>Điền địa chỉ IP và mật khẩu thiết bị để chuyển sang chế độ LAN</small></div>
            </div>
            <div className={'row mt-1'}>
              <div className={'col-9'}>
                <input type="text" required={true} value={networkIP} placeholder={'Địa chỉ IP'} onChange={handleChangeNetworkIP} className={'form-control'}/>
                <input type="text" value={appliancePassword} placeholder={'Mật khẩu thiết bị'} onChange={handleChangeAppliancePassword} className={'form-control mt-1'}/>
              </div>
              <div className={'col-3'}>
                <div>&nbsp;</div>
                <button className={'btn btn-sm btn-outline-primary'} onClick={onSwitchNetworkMode}
                        data-bs-toggle="collapse" href={networkMode === modeInternet ? `#collapse_network_mode` : ''}
                        role="button" aria-expanded="false"
                        aria-controls={networkMode === modeInternet ? `collapse_network_mode` : ''}>Chuyển
                </button>
              </div>
            </div>
          </div>
        </div>

        {!isEditingName &&
        <h5 className="mt-4">
          <b>{appliance.name}</b>
          {permission.role === 1 &&
          <i className="mx-3 fa-regular fa-pen-to-square" onClick={() => setIsEditingName(true)}/>}
        </h5>}
        {isEditingName &&
        <div className="row mt-3">
          <div className="col-auto">
            <input type="text" className='form-control' ref={inputNameRef} onBlur={(e) => saveNewName(e)}
                   defaultValue={appliance.name} style={{fontSize: "18px", fontWeight: "bold"}}/>
          </div>
          <div className="col-2 d-flex align-items-center">
            <i className="fa-solid fa-check"/>
          </div>
        </div>}
        <div>
          {appliance.is_e2ee === 0 &&
          <span className="badge bg-success">
            <i className="fa-solid fa-cloud"/>
          </span>}
          &nbsp;
          <small>{appliance.uid}</small>
        </div>

        {error &&
        <div>
          <div className="alert alert-warning">
            {error}
          </div>
        </div>}
        {appliance.uid && <>
          <div className="card mt-3 border-0" style={{backgroundColor: '#e8f4f8'}}>
            <div className="card-body">
              <div className="card-title d-flex justify-content-between">
            <span className="fs-6 text-nowrap fw-bold text-primary">
              &nbsp; Số lần điều khiển
            </span>
                {permission.open_times === null ?
                  <span>Không giới hạn &nbsp;
                    <span className="badge bg-secondary">
                      <i className="fa-solid fa-infinity"/>
                    </span>
                  </span>
                  : <span className="badge bg-secondary">
                {permission.open_times} <small>/{permission.initial_open_times}</small>
                  </span>}
              </div>
            </div>
          </div>
          <div className="card mt-3 border-0" style={{backgroundColor: '#e8f4f8'}}>
            <div className="card-body">
              <div className="card-title d-flex justify-content-between">
            <span className="fs-6 text-nowrap fw-bold text-primary">
              &nbsp; Thời hạn
            </span>
                {permission.expired === null ?
                  <span> Không giới hạn &nbsp; <span className="badge bg-secondary"><i
                    className="fa-solid fa-infinity"/></span>
            </span>
                  : <span>{permission.expired}</span>
                }
              </div>
            </div>
          </div>
          <ApplianceCommand
            user={user}
            pinCode={pinCode}
            countdownAutoCommand={countdownAutoCommand}
            appliance={appliance}
            permission={permission}
            switchStatus={switchStatus}
            setSwitchStatus={(s) => setSwitchStatus(s)}
            networkMode={networkMode}
            networkIP={networkIP}
            appliancePassword={appliancePassword}
            requestUuid={requestUuid}
          />
          <div className="my-3">
            {permission.role === 1 && <small>Bạn là quản trị viên của thiết bị này</small>}
            {permission.role === 2 && <small>Bạn là thành viên của thiết bị này</small>}
            {permission.role === 3 && <small>Bạn là khách của thiết bị này</small>}
          </div>
          <div className="card border-0" style={{backgroundColor: '#e8f4f8'}}>
            <div className="card-body">
              <div className="card-title d-flex justify-content-between">
            <span className="fs-6 text-nowrap fw-bold text-primary">
              <a className="badge bg-light text-dark" data-bs-toggle="collapse" href="#collapseStatus"
                 role="button" aria-expanded="false" aria-controls="collapseStatus">
                {permission.role === 1 && <i className="fas fa-caret-down"/>}
              </a>
              &nbsp; Trạng thái điều khiển
            </span>
                {appliance.status === 1 && (<span>Có thể điều khiển</span>)}
                {appliance.status === 0 && (<span className={'text-danger'}>Đang tạm dừng điều khiển</span>)}
              </div>
              {permission.role === 1 &&
              <div className="collapse shadow-sm mt-2" id="collapseStatus">
                <div className="card card-body py-1">
                  <div className="row">
                <span className={'badge ' + (appliance.status ? 'bg-danger' : 'bg-success')}
                      onClick={handleChangeStatus}>
                  {isSendingChangeStatus ?
                    <i className="fas fa-spinner fa-pulse"/>
                    : <i className="fa-solid fa-power-off"/>}
                  &nbsp;
                  <small>Chuyển trạng thái</small>
                </span>
                  </div>
                </div>
              </div>}
            </div>
          </div>
          {(permission.role === 1 || permission.role === 2) &&
          <div className="card mt-3 border-0" style={{backgroundColor: '#e8f4f8'}}>
            <div className="card-body">
              <div className="card-title d-flex justify-content-between">
            <span className="fs-6 text-nowrap fw-bold text-primary">
              Lịch sử mở khoá
              <span className="badge bg-light text-dark" role="button">
              </span>
            </span>
                <span className="badge bg-light shadow"
                      onClick={() => setOpenShowLogs((old) => !old)}>
                <small className={'text-dark'}>Xem chi tiết</small>
              </span>
              </div>
              {quickLogs.map((log, index) => (
                <div key={'user-list-' + index}>
                  <div className={"card card-body mt-2 py-1" + (log.result === 0 ? ' text-danger' : '')}
                       style={{backgroundColor: backgroundColor, transition: "all .2s ease"}}>
                    <small>
                      <i className="fa-solid fa-info-circle"/>
                      &nbsp; {moment(log.created_at).utcOffset('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:mm:ss')}
                    </small>
                    <small className={'d-flex justify-content-between'}>
                      <span>
                        <span className={'fw-bold'}>{log.creator_alias} {log.created_by ? ' (' + log.created_by + ') ' : ''}</span>
                        <span>{actionText[log.action]} {log.payload} </span>
                      </span>
                      {[1, 3, 4].includes(log.action) && <span>{log.result === 0 ? 'Thất bại' : 'Thành công'} </span>}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </div>}
          {(permission.role === 1 || permission.role === 2) && appliance.type === 3 &&
          <AutoCommandItem appliance={appliance} refreshAutoCommand={refreshAutoCommand} autoCommands={autoCommands}/>}
          {permission.role === 1 &&
          <div className="card mt-3 border-0" style={{backgroundColor: '#e8f4f8'}}>
            <div className="card-body">
              <div className="card-title d-flex justify-content-between">
            <span className="fs-6 text-nowrap fw-bold text-primary">
              <a className="badge bg-light text-dark" data-bs-toggle="collapse" href="#collapseAdminMember"
                 role="button" aria-expanded="false" aria-controls="collapseAdminMember">
                <i className="fas fa-caret-down"/>
              </a>
              &nbsp; Quản trị viên và Khách
            </span>
                <span>
                  <span className="badge bg-light shadow"
                        onClick={() => setOpenAddMemberModal((old) => !old)}>
                <i className="fa-solid fa-plus text-dark"/>
                    &nbsp;
                    <small className={'text-dark'}>Thêm</small>
              </span>
            </span>
              </div>

              <div className="collapse shadow-sm mt-2" id="collapseAdminMember">
                <div className="card card-body mt-2 py-1"
                     onClick={() => setOpenShowPermissionModal((old) => !old)}>
                  <div className="row">
                    <div className="col-12">
                      <span className="badge bg-light text-dark">
                        <i className="fa-solid fa-eye"/>
                      </span>
                      &emsp;
                      <small>Xem danh sách</small>
                    </div>
                  </div>
                </div>
                {/*<div className="card card-body mt-2 py-1">*/}
                {/*  <div className="row">*/}
                {/*    <div className="col-12">*/}
                {/*      <span className="badge bg-light text-dark">*/}
                {/*        <i className="fa-solid fa-angles-right"/>*/}
                {/*      </span>*/}
                {/*      &emsp;*/}
                {/*      <small>Đến trang quản lý Nhóm của tôi</small>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>}
          {permission.role === 1 &&
          <div className="card mt-3 border-0" style={{backgroundColor: '#e8f4f8'}}>
            <div className="card-body">
              <div className="card-title d-flex justify-content-between">
            <span className="fs-6 text-nowrap fw-bold text-primary" data-bs-toggle="collapse"
                  href="#collapseAdminSpecifications" role="button" aria-expanded="false"
                  aria-controls="collapseAdminSpecifications">
              <a className="badge bg-light text-dark">
                <i className="fas fa-caret-down"/>
              </a>
              &nbsp; Nâng cao
            </span>
                <span></span>
              </div>

              <div className="collapse shadow-sm mt-2" id="collapseAdminSpecifications">
                <div className="card card-body py-1">
                  <div className="row">
                    <div className="col-6">
                      <small>Loại thiết bị</small>
                    </div>
                    <div className="col-6 text-left fw-bold">
                      <small>{appliance.product_name}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <small>Số serial</small>
                    </div>
                    <div className="col-6 text-left fw-bold">
                      <small>{appliance.serial}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <small>Mã hóa đầu cuối</small>
                    </div>
                    <div className="col-6 text-left">
                      {appliance.is_e2ee ?
                        <span className="badge bg-success text-dark">
                          <i className="fa-solid fa-lock"/>&nbsp;<small>ON</small>
                        </span>
                        : <span className="badge bg-light text-dark">OFF</span>}
                    </div>
                  </div>
                </div>
                <div className="card card-body py-1 mt-3">
                  <div className="row">
                    <div className="col-10">
                      <small>Nạp lại WIFI mật khẩu WIFI</small>
                    </div>
                    <div className="col-2 text-right" onClick={() => setOpenConnectionModal(true)}>
                     <span className="badge bg-light text-dark">
                      <i className="fa-solid fa-angles-right"/>
                    </span>
                    </div>
                  </div>
                </div>
                <div className="card card-body py-1 mt-3 text-danger"
                     onClick={() => setOpenSetTypePopup((old) => !old)}>
                  <div className="row">
                    <div className="col-10">
                      <small>Chế độ</small>
                    </div>
                    <div className="col-2 text-right">
                     <span className="badge bg-light text-danger">
                      <i className="fa-solid fa-angles-right"/>
                    </span>
                    </div>
                  </div>
                </div>
                <div className="card card-body py-1 mt-3 text-danger"
                     onClick={() => setOpenDeleteConfirmationPopup((old) => !old)}>
                  <div className="row">
                    <div className="col-10">
                      <small>Xóa thiết bị</small>
                    </div>
                    <div className="col-2 text-right">
                    <span className="badge bg-light text-danger">
                      <i className="fa-solid fa-angles-right"/>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          <ConnectToAppliance
            open={openConnectionModal}
            appliance={appliance}
            connectionInfo={connectionInfo}
            toggleModal={() => setOpenConnectionModal((old) => !old)}
            toggleWaitModal={() => setOpenWaitModal((old) => !old)}
          />
          <DeleteConfirmationPopup
            uid={appliance.uid}
            open={openDeleteConfirmationPopup}
            toggleModal={() => {
              setOpenDeleteConfirmationPopup((old) => !old)
            }}
            leaveChannel={handleLeaveChannel}
          />
          <SetTypePopup
            uid={appliance.uid}
            open={openSetTypePopup}
            toggleModal={() => {
              setOpenSetTypePopup((old) => !old)
            }}
          />
          <AddPermissionModal
            uid={appliance.uid}
            open={openAddMemberModal}
            toggleModal={() => setOpenAddMemberModal((old) => !old)}
          />

          <ShowPermissionModal
            uid={appliance.uid}
            open={openShowPermissionModal}
            toggleModal={() => setOpenShowPermissionModal((old) => !old)}
          />

          <ShowLogs
            uid={appliance.uid}
            open={openShowLogs}
            toggleModal={() => setOpenShowLogs((old) => !old)}
          />
        </>}
      </div>
    </>
  )
}

const actionText = {
  0: "Kết nối lần đầu.",
  1: "Gửi lệnh mở khóa",
  3: "Gửi lệnh BẬT",
  4: "Gửi lệnh TẮT",
  5: "Thiết bị",
}
