import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import QrScannerNewAppliance from './QrScannerNewAppliance'
import BeforeRegistration from './BeforeRegistration'
import permissionApi from "../../api/permissionApi";
import ConnectToAppliance from "./ConnectToAppliance";
import WaitForPairing from "./WaitForPairing";
import Cookies from 'js-cookie';
import {useAlert} from "react-alert";
import {useAuthCheck} from "../../hooks/login-hook";
import * as _ from "lodash";
import * as moment from "moment/moment";

export default function NewAppliance(props) {
  let user = useSelector((state) => state.userLog.user)
  const [openQR, setOpenQR] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openIntroModal, setOpenIntroModal] = useState(false)
  const [openWaitModal, setOpenWaitModal] = useState(false)
  const alert = useAlert()
  const navigate = useNavigate()
  const [qrCode, setQrCode] = useState('')
  const [serial, setSerial] = useState('')
  const [secret, setSecret] = useState('')
  const [appliance, setAppliance] = useState({})
  const [connectionConfigs, setConnectionConfigs] = useState({})
  const [registerSuccessfully, setRegisterSuccessfully] = useState(false)
  const [connectSuccessfully, setConnectSuccessfully] = useState(false)
  const authCheck = useAuthCheck()

  useEffect(async () => {
    if (_.isEmpty(user)) {
      user = await authCheck()
    }
    if (!user) {
      navigate('/login?next=/appliances/new')
    }
  }, [])

  const handleCheckAppliance = async (event) => {
    try {
      event.preventDefault()
      const response = await permissionApi.checkAppliance(serial, secret)
      setAppliance({
        state: response.success,
        message: response.message
      })
      if (response.data) {
        setAppliance(old => ({
          ...old,
          product_name: response.data.product_name,
          manufacturer: response.data.manufacturer,
          serial: response.data.serial
        }))
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    }
  }

  const handleRegister = async (event) => {
    event.preventDefault()
    try {
      const response = await permissionApi.register(serial, secret, appliance.name, appliance.is_e2ee ?? false)
      if (response.success) {
        setConnectionConfigs(response.data)
        if (response.data.is_e2ee) {
          Cookies.set('pin-' + user.phone_number + '-' + response.data.uid,
            JSON.stringify({
              code: appliance.pin,
              created_at: moment().utcOffset('Asia/Ho_Chi_Minh').format('YYYY/MM/DD HH:mm:ss')
            })
          )
        } else {
          appliance.pin = response.data.master_pin ?? appliance.pin
        }

        setOpenIntroModal(true)
        setRegisterSuccessfully(true);
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + ' ' + e.response.data.message)
    }
  }

  const handleQrBtnClick = () => {
    setOpenQR((old) => !old)
  }

  const registerBtn = registerSuccessfully ?
    (<button
      type="submit"
      className="form-control btn btn-success rounded submit px-3 disabled"
    >
      <i className="fa-solid fa-circle-check"/> Đăng ký thành công
    </button>)
    : (<button
      type="submit"
      className="form-control btn btn-success rounded submit px-3"
      onClick={() => setOpenModal((old) => !old)}
    >
      Đăng ký thiết bị
    </button>)

  const connectBtn = registerSuccessfully ?
    (connectSuccessfully ?
        <button
          type="submit"
          className="form-control btn btn-success rounded submit px-3 mt-3 disabled"
        >
          <i className="fa-solid fa-circle-check"/> Nạp dữ liệu lần đầu thành công
        </button>
        : <button
          type="submit"
          className="form-control btn btn-success rounded submit px-3 mt-3"
          onClick={() => setOpenIntroModal((old) => !old)}
        >
          Nạp dữ liệu lần đầu cho thiết bị...
        </button>
    ) : ''

  return (
    <>
      <div className="container" style={{maxWidth: '500px'}}>
        <Link to="/appliances" style={{textDecoration: 'none'}}>
          <i className="fas fa-angle-double-left"/> Thiết bị của tôi
        </Link>
        <h5 className="my-4 d-flex justify-content-between">
          <span>Thêm thiết bị mới</span>
          <i className="fa-solid fa-qrcode" onClick={handleQrBtnClick} style={{fontSize: '40px'}}/>
          <span>&nbsp;</span>
        </h5>
        <small>Nhấn vào biểu tượng QR và quét mã dưới đáy thiết bị. Hoặc nhập thủ công
          <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false"
             aria-controls="collapseExample"> tại đây</a>
        </small>
        <form className="collapse" id="collapseExample" action="#">
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-7">
                <div>
                  <label className="form-control-placeholder" htmlFor="username">
                    Mã serial:
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="serial"
                    className="form-control"
                    value={serial}
                    onChange={(e) => {
                      setSerial(e.target.value)
                    }}
                    onBlur={(e) => {
                      setQrCode(serial)
                    }}
                    required
                  />
                </div>
              </div>
              <div className="col-5">
                <div>
                  <label className="form-control-placeholder" htmlFor="username">
                    Mã bảo mật:
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="secret_code"
                    className="form-control"
                    value={secret}
                    onChange={(e) => {
                      setSecret(e.target.value)
                    }}
                    onBlur={(e) => {
                      setQrCode(secret)
                    }}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-2">
            <button
              type="submit"
              className="form-control btn btn-primary rounded submit px-3"
              onClick={handleCheckAppliance}
            >
              Kiểm tra thiết bị
            </button>
          </div>
        </form>
        <hr/>
        <div className="form-group mt-3">
          <label className="form-control-placeholder" htmlFor="username">
            Tên thiết bị:
          </label>
          <b>{appliance.product_name}</b>
        </div>

        <div className="form-group mt-3">
          <label className="form-control-placeholder" htmlFor="username">
            Nhà sản xuất:
          </label>
          <b>{appliance.manufacturer}</b>
        </div>


        <div className="form-group mt-3">
          <label className="form-control-placeholder" htmlFor="username">
            Số serial:
          </label>
          <b>{appliance.serial}</b>
        </div>

        <div className={appliance.state ? 'text-success mt-3' : 'text-danger mt-3'}>{appliance.message}</div>
        {appliance.state === true &&
        <div className="form-group mt-3">
          {registerBtn}
          {connectBtn}
        </div>}
      </div>

      <QrScannerNewAppliance
        open={openQR}
        toggleOffcanvas={() => setOpenQR((old) => !old)}
        getQrCode={(qrCode) => {
          // setSecret(qrCode)
          // setQrCode(qrCode)
        }}
      />

      <BeforeRegistration
        open={openModal}
        toggleModal={() => setOpenModal((old) => !old)}
        appliance={appliance}
        handleRegister={handleRegister}
      />

      <ConnectToAppliance
        open={openIntroModal}
        appliance={appliance}
        connectionConfigs={connectionConfigs}
        toggleModal={() => setOpenIntroModal((old) => !old)}
        toggleWaitModal={() => setOpenWaitModal((old) => !old)}
      />

      <WaitForPairing
        open={openWaitModal}
        appliance={appliance}
        connectionConfigs={connectionConfigs}
        toggleModal={() => setOpenWaitModal((old) => !old)}
      />
    </>
  )
}
