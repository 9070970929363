import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import applianceApi from '../../api/applianceApi'
import Cookies from 'js-cookie'
import {useAlert} from "react-alert"
import {useAuthCheck} from "../../hooks/login-hook"
import * as _ from 'lodash'

export default function Backup(props) {
  let user = useSelector((state) => state.userLog.user)
  const navigate = useNavigate()
  const [appliances, setAppliances] = useState({})
  const [isSendingSync, setIsSendingSync] = useState(false)
  const alert = useAlert()
  const authCheck = useAuthCheck()

  useEffect(async () => {
    if (_.isEmpty(user)) {
      user = await authCheck()
    }
    if (!user) {
      navigate('/login?next=/backup')
    }
  }, [])


  useEffect(async () => {
    try {
      const response = await applianceApi.getAppliances()
      if (response.success) {
        setAppliances(response.data)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
    }
  }, [])

  const handleSync = async () => {
    try {
      setIsSendingSync(true)
      const response = await applianceApi.getPinsSavedOnServer()
      if (!response.success) {
        alert.error(response.message)
        return
      }
      const pinsOnServer = response.data

      // Nhập mật khẩu để giải nén PIN

      var pinsNeedSync = {}
      Object.values(appliances).flat().forEach((appliance) => {
          let pin = ''
          try {
            let pinJSON = Cookies.get('pin-' + user.phone_number + '-' + appliance.uid)
            if (!_.isEmpty(pinJSON)) {
              pin = JSON.parse(pinJSON)
            }
          } catch (e) {
          }
          if (pinsOnServer[appliance.uid].pin === pin.code) {
            pinsNeedSync[appliance.uid] = pin
          } else {
            if (_.isEmpty(pin)) {
              if (!_.isEmpty(pinsOnServer[appliance.uid].pin)) {
                pinsNeedSync[appliance.uid] = {
                  code: pinsOnServer[appliance.uid].pin,
                  created_at: parseInt(pinsOnServer[appliance.uid].pin_created_at)
                }
              }
            } else {
              if (pinsOnServer[appliance.uid].pin_created_at > pin.created_at) {
                pinsNeedSync[appliance.uid] = {
                  code: pinsOnServer[appliance.uid].pin,
                  created_at: parseInt(pinsOnServer[appliance.uid].pin_created_at)
                }
              } else {
                pinsNeedSync[appliance.uid] = pin
              }
            }
          }
        }
      )
      // Xóa tất cả PIN trong Cookie
      const allPins = Cookies.get()
      Object.keys(allPins).forEach((prefix) => {
        if (prefix.match('pin-' + user.phone_number)) {
          Cookies.remove(prefix)
        }
      })

      // Lưu các PIN cần đồng bộ vào cookie + gửi lên server
      Object.keys(pinsNeedSync).forEach((uid) => {
        Cookies.set('pin-' + user.phone_number + '-' + uid, JSON.stringify(pinsNeedSync[uid]), { expires: 3650, secure: true })
      })
      const responseSync = await applianceApi.syncPins(pinsNeedSync)

      if (!responseSync.success) {
        alert.error(responseSync.message)
        return
      }

      alert.success('Hoàn tất đồng bộ.')
      setAppliances({...appliances})
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    } finally {
      setIsSendingSync(false)
    }
  }

  return (
    <>
      <div className="container" style={{maxWidth: '500px'}}>
        <h5 className="mb-3">Đồng bộ mã PIN điều khiển thiết bị</h5>
        <div>
          <small>Đồng bộ mã PIN giúp bạn sử dụng mã PIN khi đăng nhập ở các thiết bị khác.</small>
        </div>
        <div className={'my-3'}>
          <b>Lần đồng bộ gần nhất: </b> <span>03/01/2023 01:58</span>
        </div>
        <div>
          <SyncBtn handleSync={handleSync} isSendingSync={isSendingSync}/>
        </div>
        <hr/>
        <div
          className="card mt-3 shadow"
          style={{backgroundColor: '#f0fff0'}}
        >
          <div className="card-body">
            <div className="card-title d-flex justify-content-between">
            <span
              className="fs-6 text-nowrap fw-bold text-success"
              data-bs-toggle="collapse"
              href="#collapsePasswordTab"
              role="button"
              aria-expanded="true"
              aria-controls="collapsePasswordTab"
            >
              <a className="badge bg-light text-dark">
                <i className="fas fa-caret-down"/>
              </a>
              &nbsp; Mật khẩu đồng bộ
            </span>
            </div>

            <div
              className="collapse shadow-sm mt-2 show"
              id="collapsePasswordTab"
            >
              <div className="card card-body py-1 mt-3">
                <div className="row">
                  <div className="col-10">
                    <small>Sử dụng mật khẩu đồng bộ</small>
                  </div>
                </div>
              </div>

              <div className="card card-body py-1 mt-3">
                <div className="row">
                  <div className="col-10">
                    <small>Đặt mật khấu</small>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div
          className="card mt-3 shadow"
          style={{backgroundColor: '#f0fff0'}}
        >
          <div className="card-body">
            <div className="card-title d-flex justify-content-between">
            <span
              className="fs-6 text-nowrap fw-bold text-success"
              data-bs-toggle="collapse"
              href="#collapseAdminSpecifications"
              role="button"
              aria-expanded="true"
              aria-controls="collapseAdminSpecifications"
            >
              <a className="badge bg-light text-dark">
                <i className="fas fa-caret-down"/>
              </a>
              &nbsp; Danh sách thiết bị
            </span>
            </div>

            <div
              className="collapse shadow-sm mt-2 show"
              id="collapseAdminSpecifications"
            >
              {Object.values(appliances).flat().map((appliance) => {
                const pin = Cookies.get('pin-' + user.phone_number + '-' + appliance.uid);
                return (
                  <div className="card card-body py-1 mt-3" key={appliance.uid}>
                    <div className="row">
                      <div className="col-10">
                        <small>{appliance.name}</small>
                      </div>
                      <div className="col-2 text-right">
                        {appliance.is_e2ee ?
                          <span className="badge bg-success text-dark">
                            <i className="fa-solid fa-cloud"/>
                          </span>
                          : pin ?
                            <span className="badge bg-light text-success">
                            <i className="fa-solid fa-circle-check"/>
                          </span>
                            : <span className="badge bg-light text-danger">
                            <i className="fa-solid fa-circle-time"/>
                          </span>}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SyncBtn = (props) => {
  return (
    <Box>
      <Fab
        color={props.isSendingSync ? 'light' : 'success'}
        onClick={props.handleSync}
        size="medium"
        aria-label="add"
        variant="extended"
        disabled={props.isSendingSync}
      >
        {props.isSendingSync && <i className="fas fa-spinner fa-pulse"/>}
        {props.isSendingSync ? ' Đang đồng bộ' : 'Đồng bộ giữa điện thoại và Server'}
      </Fab>
    </Box>
  )
}
