import React, {useEffect, useState} from 'react'
import {Badge, Button, ButtonGroup, Col, Modal, Row, ToggleButton} from 'react-bootstrap'
import ErrorMessage from '../Notification/ErrorMessage'
import permissionApi from "../../api/permissionApi"
import {useAlert} from "react-alert"
import * as _ from 'lodash'

export default function ShowPermissionModal(props) {
  const [submitError, setSubmitError] = useState('')
  const [permissionRole, setPermissionRole] = useState(3)
  const [memberRoleList, setMemberRoleList] = useState([])
  const [guessRoleList, setGuessRoleList] = useState([])
  const [groupMode, setGroupMode] = useState(0)
  const [openTimes, setOpenTimes] = useState(2)
  const [isLoading, setIsLoading] = useState(false)
  const alert = useAlert()
  const [permissionList, setPermissionList] = useState([])
  const [groupList, setGroupList] = [{}]

  useEffect(async (e) => {
    if (!props.open) return
    try {
      setIsLoading(true)
      const response = await permissionApi.getPermissionsOfAppliance(props.uid)
      if (response.success) {
        const guessList = response.data.filter((item) => {
          return item.role === 3
        })
        setGuessRoleList(guessList)

        const memberList = response.data.filter((item) => {
          return item.role === 2
        })
        setMemberRoleList(memberList)

      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }, [props.open])

  return (
    <>
      <Modal show={props.open} onHide={props.toggleModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Xem &emsp;
          </Modal.Title>
          <ButtonGroup>
            <ToggleButton id={`radio-2`} type="radio" variant={'outline-success'} name="permission_role" required
                          value={3} checked={permissionRole === 3}
                          onChange={(e) => {
                            setPermissionRole(3)
                          }}
            >
              Khách
            </ToggleButton>
            <ToggleButton id={`radio-1`} type="radio" variant={'outline-warning'} name="permission_role" required
                          value={2} checked={permissionRole === 2}
                          onChange={(e) => {
                            setPermissionRole(2)
                          }}
            >
              Quản trị viên
            </ToggleButton>
          </ButtonGroup>
          &emsp; {isLoading && <i className="fas fa-spinner fa-pulse"/>}
        </Modal.Header>
        <Modal.Body>
          <ListSlide permissionList={permissionRole === 3 ? guessRoleList : memberRoleList}
                     setPermissionList={setPermissionList}
                     setIsLoading={setIsLoading}
                     uid={props.uid}/>
          {submitError && <ErrorMessage error={submitError}/>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.toggleModal()}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function ListSlide(props) {
  const alert = useAlert()

  const handleAddNew = () => {
    props.toggleModel()
  }

  const handleDeletePermission = async (index) => {
    const confirmRunning = window.confirm('Bạn có chắc chắn muốn xóa?.')
    if (!confirmRunning) return

    try {
      props.setIsLoading(true)
      const response = await permissionApi.removePermissions(props.permissionList[index].id, props.uid)
      if (response.success) {
        const tmpList = props.permissionList
        props.setPermissionList(tmpList.splice(index, 1))
        alert.success(response.message)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    } finally {
      props.setIsLoading(false)
    }
  }

  return (
    <>
      {_.isEmpty(props.permissionList) &&
      <div className={'text-center'}><small>Danh sách rỗng</small></div>}
      {props.permissionList.map((member, index) => (
        <div key={'user-list-' + index}>
          <Row className={'gx-1 mb-1'}
               data-bs-toggle="collapse" href={"#collapsePermissionList" + index} role="button"
               aria-expanded="false" aria-controls={"#collapsePermissionList" + index}>
            <Col xs={6}>
              <div className={'form-control text-truncate'}>{member.alias ? member.alias : '-'}</div>
            </Col>
            <Col xs={5}>
              <div className="form-control">{member.phone_number !== 'null' ? member.phone_number : '-'}</div>
            </Col>
            <Col xs={1}><span className={'badge bg-light text-dark'}><i
              className="fa-solid fa-caret-down"/></span></Col>
          </Row>
          <div className="collapse shadow-sm my-2" id={"collapsePermissionList" + index}>
            <div className="card card-body py-1">
              <div className="row">
                <Col xs={4}><small>Số lần mở</small></Col>
                <Col xs={8}>
                  <div>{isNaN(member.open_times) ? 'Không giới hạn' : (member.open_times + '/' + member.initial_open_times) }</div>
                </Col>
              </div>
              <div className="row">
                <Col xs={4}><small>Hiệu lực</small></Col>
                <Col xs={8}>
                  <div>{member.expired ? member.expired : 'Không giới hạn'}</div>
                </Col>
              </div>
              <div className="row">
                <Col xs={"3"} className={'text-center text-danger text-decoration-underline'}
                     onClick={() => handleDeletePermission(index)}><small>Xóa</small></Col>
                <Col xs={"5"} className={'text-center text-decoration-underline'}><small>Chặn tạm thời</small></Col>
                <Col xs={"4"} className={'text-center text-decoration-underline'}><small>Xem lịch sử</small></Col>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Row>
        <Col xs={2}><Badge bg="secondary" onClick={() => handleAddNew()}>Thêm</Badge></Col>
        <Col>
          <hr/>
        </Col>
      </Row>
    </>
  )
}
