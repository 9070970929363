import axiosClient from './axiosClient'
import Cookies from 'js-cookie'

const permissionApi = {
  checkAppliance: (serial, secret) => {
    const url = 'appliance/check-appliance-can-be-registered'
    const token = Cookies.get('access_token')
    return axiosClient.get(url, {
      params: {
        serial: serial,
        secret: secret
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  },
  register: (serial, secret, name, isE2ee) => {
    const url = 'permission/register'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, {
      serial: serial,
      secret: secret,
      name: name,
      is_e2ee: isE2ee
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
  unregister: (uid, password) => {
    const url = 'permission/unregister'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, {
      uid: uid,
      password: password
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
  setPermissions: (uid, permissionList, permissionRole, openTimes) => {
    const url = 'permission/set-permissions'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, {
      uid: uid,
      users: permissionList,
      role: permissionRole,
      group_mode: false,
      open_times: permissionRole === 2 ? null : Number(openTimes),
      expired: null,
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
  removePermissions: (id, uid) => {
    const url = 'permission/remove-permission'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, {
      uid: uid,
      id: id
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
  getPermissionsOfAppliance: (uid) => {
    const url = 'permission/get-permissions'
    const token = Cookies.get('access_token')
    return axiosClient.get(url, {
      params: {
        uid: uid,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
  getLogs: (uid, page, limit) => {
    const url = 'appliance/get-logs'
    const token = Cookies.get('access_token')
    return axiosClient.get(url, {
      params: {
        uid: uid,
        page: page,
        limit: limit
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
}


export default permissionApi
