import React, {useEffect, useRef, useState} from 'react'
import Cookies from 'js-cookie'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import loginApi from '../../../api/loginApi'
import {userLogSlice} from '../../../redux/User/userLogSlice'
import ErrorMessage from '../../Notification/ErrorMessage'
import PasswordInput from './PasswordInput'
import {useAlert} from "react-alert";
import {useAuthCheck} from "../../../hooks/login-hook"
import * as _ from 'lodash'

export default function Login(props) {
  const [phone_number, setPhonenumber] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const passwordStartRef = useRef()
  const loginRef = useRef()
  const user = useSelector((state) => state.userLog.user)
  const [submitError, setSubmitError] = useState('')
  const alert = useAlert()
  const authCheck = useAuthCheck()

  useEffect(async () => {
    if (await authCheck()) {
      const windowUrl = window.location.search;
      const params = new URLSearchParams(windowUrl);
      if (_.isEmpty(params['next'])) {
        navigate('/')
      } else {
        navigate(params['next'])
      }
    }
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const response = await loginApi.login({
        phone_number: phone_number,
        password: password,
      })
      if (response.success) {
        alert.success("Đăng nhập thành công")
        Cookies.remove('access_token')
        Cookies.set('access_token', response.data.access_token, { expires: 30, secure: true })
        dispatch(
          userLogSlice.actions.login({
            user: response.data.user,
          }),
        )
        navigate('/')
      } else {
        alert.error(response.message)
      }
    } catch (error) {
      setSubmitError(error.response.data.error)
      alert.error(error.response.data.message)
    }
  }

  const handlePhonenumberChange = (event) => {
    setPhonenumber(event.target.value)
    if (event.target.value.length === 10) {
      passwordStartRef.current.focus()
    }
  }

  useEffect(() => {
    if (password.length === 6) {
      loginRef.current.click()
    }
  }, [password])

  return (
    <>
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="wrap">
                <h3 className="text-center my-3">
                  <img
                    src={require('../../../datas/images/onkey_logo.png')}
                    width="40"
                    height="40"
                    className="d-inline-block"
                  />
                  <span style={{color: '#72d3fe'}}>
                    Chìa khoá của tương lai
                  </span>
                </h3>
                <div className="login-wrap p-4 p-md-5">
                  <div className="d-flex">
                    <div className="w-100">
                      <h3 className="mb-4">Đăng nhập</h3>
                    </div>
                    <div className="w-100">
                      <h1 className="social-media d-flex justify-content-end">
                        <span
                          href="#"
                          className="social-icon d-flex align-items-center justify-content-center"
                        >
                          <i className="fa-brands fa-google-plus"/>
                        </span>
                        &nbsp;
                        <span
                          href="#"
                          className="social-icon d-flex align-items-center justify-content-center"
                        >
                          <i className="fa-brands fa-facebook"/>
                        </span>
                      </h1>
                    </div>
                  </div>
                  <form
                    action="#"
                    onSubmit={handleSubmit}
                    className="signin-form"
                  >
                    <div className="form-group mt-3">
                      <label
                        className="form-control-placeholder"
                        htmlFor="phone_number"
                      >
                        Số điện thoại
                      </label>
                      <input
                        type="number"
                        name="phone_number"
                        onChange={handlePhonenumberChange}
                        className="form-control"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label
                        className="form-control-placeholder"
                        htmlFor="password"
                      >
                        Mật khẩu
                      </label>
                      <PasswordInput
                        ref={passwordStartRef}
                        sendPasswordToParent={(password) => {
                          setPassword(password)
                        }}
                      />
                    </div>
                    {submitError && <ErrorMessage error={submitError}/>}
                    <div className="form-group mt-3">
                      <button
                        type="submit"
                        ref={loginRef}
                        className="form-control btn btn-primary rounded submit px-3"
                      >
                        Đăng nhập
                      </button>
                    </div>
                    <div className="form-group d-md-flex">
                      <div className="w-50 text-md-right">
                        <a href="#">Quên mật khẩu ?</a>
                      </div>
                    </div>
                  </form>
                  <p className="text-center mt-5">
                    Chưa có tài khoản?{' '}
                    <Link data-toggle="tab" to="/register">
                      Đăng ký ngay
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
