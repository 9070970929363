import React, {useEffect, useState} from 'react'
import {Badge, Button, Col, Modal, Row} from 'react-bootstrap'
import ErrorMessage from '../Notification/ErrorMessage'
import permissionApi from "../../api/permissionApi"
import {useAlert} from "react-alert"
import * as _ from 'lodash'
import * as moment from 'moment'

export default function ShowLogs(props) {
  const [submitError, setSubmitError] = useState('')
  const [logs, setLogs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const alert = useAlert()

  useEffect(async (e) => {
    if (!props.open) return
    try {
      setIsLoading(true)
      const response = await permissionApi.getLogs(props.uid)
      if (response.success) {
        setLogs(response.data)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }, [props.open])

  return (
    <>
      <Modal show={props.open} onHide={props.toggleModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            Xem lịch sử &emsp;
          </Modal.Title>
          &emsp; {isLoading && <i className="fas fa-spinner fa-pulse"/>}
        </Modal.Header>
        <Modal.Body>
          <ListSlide logs={logs}/>
          {submitError && <ErrorMessage error={submitError}/>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.toggleModal()}>
            Đóng
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function ListSlide(props) {

  const handleAddNew = () => {
    props.toggleModel()
  }

  return (
    <>
      {_.isEmpty(props.logs) &&
      <div className={'text-center'}><small>Danh sách rỗng</small></div>}
      {props.logs.map((log, index) => (
        <div key={'user-list-' + index}>
          <div className={"card card-body mt-2 py-1" + (log.result === 0 ? ' text-danger' : '')}>
            <small>
              <i className="fa-solid fa-info-circle"/>
              &nbsp; {moment(log.created_at).utcOffset('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:mm:ss')}
            </small>
            <small className={'d-flex justify-content-between'}>
              <span>
                <span className={'fw-bold'}>{log.creator_alias} {log.created_by ? ' (' + log.created_by + ') ' : ''}</span>
                <span>{actionText[log.action]} {log.payload} </span>
              </span>
              {[1,3,4].includes(log.action) && <span>{log.result === 0 ? 'Thất bại' : 'Thành công'} </span>}
            </small>
          </div>
        </div>
      ))}

      <Row>
        <Col xs={2}><Badge bg="secondary" onClick={() => handleAddNew()}>Thêm</Badge></Col>
        <Col>
          <hr/>
        </Col>
      </Row>
    </>
  )
}

const actionText = {
  0: "Kết nối lần đầu.",
  1: "Gửi lệnh mở khóa",
  3: "Gửi lệnh BẬT",
  4: "Gửi lệnh TẮT",
  5: "Thiết bị",
}
