import React, {useState} from 'react'
import {Badge, Button, ButtonGroup, Col, Form, Modal, Row, ToggleButton} from 'react-bootstrap'
import ErrorMessage from '../Notification/ErrorMessage'
import permissionApi from "../../api/permissionApi"
import {useAlert} from "react-alert"
import * as _ from 'lodash'

export default function AddPermissionModal(props) {
  const [submitError, setSubmitError] = useState('')
  const [permissionRole, setPermissionRole] = useState(3)
  const [groupMode, setGroupMode] = useState(0)
  const [openTimes, setOpenTimes] = useState(2)
  const [isSaving, setIsSaving] = useState(false)
  const alert = useAlert()
  const [groupList, setGroupList] = [{}]
  const [permissionList, setPermissionList] = useState([{
    alias: '',
    phone_number: '',
  }])

  const handleSubmit = async (e) => {
    let validation = true;
    permissionList.map((item) => {
      if (_.isEmpty(item.phone_number) || !_.isNumber(Number(item.phone_number))) {
        alert.error("Số điện thoại không hợp lệ")
        validation = false
      }
    })
    if (!validation) return
    try {
      setIsSaving(true)
      const response = await permissionApi.setPermissions(props.uid, permissionList, permissionRole, openTimes)
      if (response.success) {
        alert.success(response.message)
        props.toggleModal()
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    } finally {
      setIsSaving(false)
    }
  }
  return (
    <>
      <Modal show={props.open} onHide={props.toggleModal} backdrop="static" keyboard={false}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Thêm &emsp;
            </Modal.Title>
            <ButtonGroup>
              <ToggleButton id={`radio-1`} type="radio" variant={'outline-success'} name="permission_role" required
                            value={3} checked={permissionRole === 3}
                            onChange={(e) => {
                              setPermissionRole(3)
                            }}
              >
                Khách
              </ToggleButton>
              <ToggleButton id={`radio-2`} type="radio" variant={'outline-warning'} name="permission_role" required
                            value={2} checked={permissionRole === 2}
                            onChange={(e) => {
                              setPermissionRole(2)
                            }}
              >
                Quản trị viên
              </ToggleButton>
            </ButtonGroup>
          </Modal.Header>
          <Modal.Body>
            <ListSlide permissionList={permissionList} setPermissionList={setPermissionList}/>

            <Form.Group className="mb-3">
              <Row>
                <Col xs={4}><small>Số lần mở</small></Col>
                <Col xs={8}>
                  <Form.Control type={permissionRole === 2 ? "text" : "number"} name="total_opening_times"
                                pattern="[0-9]*" inputMode="numeric"
                                required={permissionRole === 3} autoFocus
                                readOnly={permissionRole === 2}
                                value={permissionRole === 2 ? 'Không giới hạn' : openTimes}
                                onChange={(e) => setOpenTimes(e.target.value)}
                  />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              <Row>
                <Col xs={4}>
                  <small>Hiệu lực</small>
                </Col>

                <Col xs={8}>
                  <Form.Select
                    name="valid_time"
                    defaultValue={'-1'}
                    aria-label="Default select example"
                  >
                    {permissionRole === 3 ? (
                      <>
                        <option value="86400">24 giờ</option>
                        <option value="172800">48 giờ</option>
                        <option value="604800">1 tuần</option>
                        <option value="2592000">1 tháng</option>
                        <option value="7776000">3 tháng</option>
                      </>) : ''}
                    <option value="-1">Không giới hạn</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>

            {submitError && <ErrorMessage error={submitError}/>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => props.toggleModal()}>
              Đóng
            </Button>
            <Button variant="primary" onClick={handleSubmit} disabled={isSaving}>
              {isSaving && <i className="fas fa-spinner fa-pulse"/>}
              Lưu
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

function ListSlide(props) {

  const handleAddNew = () => {
    props.setPermissionList([...props.permissionList, {
      alias: '',
      phone_number: '',
    }])
  }

  const handleRemove = (index) => {
    // assigning the list to temp variable
    const temp = [...props.permissionList];

    // removing the element using splice
    temp.splice(index, 1);
    props.setPermissionList(temp)
  }

  const updateList = (e, index) => {
    const tmp = [...props.permissionList]
    tmp[index][e.target.name] = e.target.value
    props.setPermissionList(tmp)
  }

  return (
    <>
      {props.permissionList.map((member, index) => (
        <Row className={'gx-1 mb-1'} key={'user-list-' + index}>
          <Col xs={6}>
            <Form.Control type="text" placeholder='Tên gợi nhớ' name="alias" value={member.alias}
                          onChange={(e) => updateList(e, index)} required
                          autoFocus/>
          </Col>
          <Col xs={5}>
            <Form.Control
              type="number" placeholder="Số điện thoại" name="phone_number" value={member.phone_number}
              onChange={(e) => updateList(e, index)}
              pattern="[0-9]*" inputMode="numeric"
              required autoFocus
            />
          </Col>
          <Col xs={1}><span><i className="fa-regular fa-circle-xmark" onClick={() => handleRemove(index)}/></span></Col>
        </Row>
      ))}

      <Row>
        <Col xs={2}><Badge bg="secondary" onClick={() => handleAddNew()}>Thêm</Badge></Col>
        <Col>
          <hr/>
        </Col>
      </Row>
    </>
  )
}
