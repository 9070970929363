import React from 'react'
import loginApi from "../api/loginApi";
import {userLogSlice} from "../redux/User/userLogSlice";
import {useAlert} from "react-alert";
import {useDispatch} from 'react-redux'

import * as _ from 'lodash'

export function useAuthCheck() {
  const alert = useAlert()
  const dispatch = useDispatch()

  async function authCheck () {
    try {
      const response = await loginApi.getUser()
      if (!_.isEmpty(response)) {
        if (response.success === true) {
          dispatch(
            userLogSlice.actions.login({
              user: response.data,
            }),
          )
          return response.data
        }
        dispatch(
          userLogSlice.actions.login({
            user: null,
          }),
        )
        alert.error("Token không hợp lệ hoặc quá hạn. Vui lòng đăng nhập lại")
        return false
      }
      dispatch(
        userLogSlice.actions.login({
          user: null,
        }),
      )
      return false
    } catch (e) {
      alert.error("Có lỗi khi đăng nhập." + e.response.data.message)
      dispatch(
        userLogSlice.actions.login({
          user: null,
        }),
      )
      return false;
    }
  }

  return authCheck
}
