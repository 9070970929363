import React, {useState} from 'react'
import {Form, Offcanvas} from 'react-bootstrap'
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import applianceApi from "../../api/applianceApi";

export default function SetTypePopup(props) {

  const [isChangingMode, setIsChangingMode] = useState(false)
  const [changeableTypes, setChangeableTypes] = useState([])
  const [selectedMode, setSelectedMode] = useState(0)
  const [notification, setNotification] = useState('')

  const [error, setError] = useState('')
  const navigate = useNavigate()
  const alert = useAlert();

  const handleClose = () => {
    props.toggleModal()
    setChangeableTypes([])
    setNotification('')
  }


  const handleShow = async (e) => {
    setIsChangingMode(false)
    try {
      const response = await applianceApi.getCurrentTypeAndChangeableTypes(props.uid)
      if (response.success) {
        setSelectedMode(Number(response.data.type))
        setChangeableTypes(response.data.changeable_types)
      } else {
        setNotification(response.message)
      }
    } catch (e) {
      setNotification(e.response.data.message)
    }
  }

  const handleChangeMode = (e) => {
    setIsChangingMode(true)
    setSelectedMode(Number(e.target.value))
  }

  const handleChangeBtn = async () => {
    try {
      const response = await applianceApi.setType(props.uid, selectedMode)
      if (response.success) {
        alert.success(response.message)
        navigate('/appliances')
      } else {
        alert.error(response.message)
        setError(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
      setError(e.response.data.message)
    } finally {
      setTimeout(() => setError(''), 4000)
    }
  }

  return (
    <>
      <style type="text/css">
        {`
        .offcanvas {
        height: auto
        }
        `}
      </style>

      <Offcanvas
        show={props.open}
        onShow={handleShow}
        backdrop={true}
        placement="bottom"
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className={'text-danger'}>{'Chọn chế độ thiết bị'}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {changeableTypes.map((mode, index) => (
            <Form.Check
              key={`appliance-type-${index}`}
              className={'mb-3'}
              id={`appliance-type-${mode.id}`}
              type={"radio"}
              value={Number(mode.id)}
              name={`appliance_mode`}
              defaultChecked={mode.id === selectedMode}
              label={`${mode.text}`}
              onChange={handleChangeMode}
            />
          ))}
          {notification &&
          <div>
            {notification}
          </div>}
          <div className='mb-2'>
            {isChangingMode &&
            <div>
              <div><small className='fw-bold'>Bạn đang chuyển chế độ của thiết bị. Hãy lưu ý:</small></div>
              <div><small>- Việc chuyển chế độ có thể ảnh hưởng đến hoạt động của thiết bị.</small></div>
            </div>}
            <div className='my-3'>
              <button onClick={handleChangeBtn} disabled={!isChangingMode}
                      className="btn btn-danger btn-block w-100 mt-3">Chuyển chế độ
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
