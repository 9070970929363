import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import applianceApi from '../../api/applianceApi'
import {useAlert} from "react-alert"
import {useAuthCheck} from "../../hooks/login-hook"
import * as _ from 'lodash'
import AddAutoCommandPopup from "./AddAutoCommandPopup";
import autoCommandApi from "../../api/autoCommandApi";
import AutoCommandItem from "./AutoCommandItem";

export default function AutoCommand(props) {
  let user = useSelector((state) => state.userLog.user)
  const navigate = useNavigate()
  const [appliances, setAppliances] = useState([])
  const [autoCommands, setAutoCommands] = useState({})

  const [isSendingSync, setIsSendingSync] = useState(false)
  const alert = useAlert()
  const authCheck = useAuthCheck()
  const [openAutoCommandPopup, setOpenAutoCommandPopup] = useState(false)

  useEffect(async () => {
    if (_.isEmpty(user)) {
      user = await authCheck()
    }
    if (!user) {
      navigate('/login?next=/auto-command')
    }
  }, [])


  useEffect(async () => {
    try {
      const appliancesResponse = await applianceApi.getAppliances()
      const response = await autoCommandApi.getCommandsAllAppliances()
      if (response.success && appliancesResponse.success) {
        setAppliances(Object.values(appliancesResponse.data).flat().filter(appliance => appliance.type === 3))
        setAutoCommands(response.data)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
    }
  }, [])

  const refreshAutoCommand = (autoCommandId, uid) => {
    const newAutoCommands = autoCommands[uid]
    delete newAutoCommands[autoCommandId]
    setAutoCommands(old => ({...old, newAutoCommands}))
  }

  return (
    <>
      <div className="container" style={{maxWidth: '500px'}}>
        <h5 className="mb-3 d-flex justify-content-between">
          <span>Cài đặt hẹn giờ thiết bị</span>
          <AddAutoCommandBtn setOpenAutoCommandPopup={setOpenAutoCommandPopup} isSendingSync={isSendingSync}/>
        </h5>
        <div><small>Các lệnh tắt, mở,... sẽ được thực hiện tự động. Tính năng này chỉ khả dụng với điều kiện:</small>
        </div>
        <div><small>- Thiết bị công tắc.</small></div>
        <div><small>- Bạn là Quản trị viên của thiết bị.</small></div>
        {appliances.map((appliance) => {
          return (
            <AutoCommandItem appliance={appliance} key={appliance.uid} refreshAutoCommand={refreshAutoCommand}
                             autoCommands={autoCommands[appliance.uid] ?? []}/>
          )
        })}
      </div>
      <AddAutoCommandPopup appliances={appliances} open={openAutoCommandPopup}
                           toggleModal={() => {
                             setOpenAutoCommandPopup((old) => !old)
                           }}
      />
    </>
  )
}

const AddAutoCommandBtn = (props) => {
  return (
    <Box>
      <Fab onClick={() => props.setOpenAutoCommandPopup((old) => !old)} size="small" aria-label="add" variant="extended"
           disabled={props.isSendingSync}>
        {props.isSendingSync && <i className="fas fa-spinner fa-pulse"/>}
        {props.isSendingSync ? 'Thêm lệnh hẹn' : '+ Thêm lệnh hẹn'}
      </Fab>
    </Box>
  )
}
