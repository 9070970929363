import React from 'react'
import {Link} from 'react-router-dom'

export default function SmartApplianceItem(props) {
  return (
    <>
      <Link style={{ textDecoration: 'none', color: 'black' }} to={'/appliances/' + props.applianceUid}>
        <div
          className="card mt-4 shadow"
          style={{backgroundColor: '#e8f4f8'}}
        >
          <div className="card-body">
            <div className="card-title d-flex justify-content-between">
              <div className="fs-6 text-nowrap fw-bold text-primary">
                {props.applianceName}
              </div>
              <span>
              {props.applianceStatus == 1 && (
                <span className="badge bg-success">ON</span>
              )}
                {props.applianceStatus == 0 && (
                  <span className="badge bg-danger">OFF</span>
                )}
                {props.applianceStatus == -1 && (
                  <span className="badge bg-warning">?</span>
                )}
            </span>
            </div>
            <div className="d-flex justify-content-between">
              <small className="">{props.applianceAddress}</small>
              <a
                className="badge bg-light text-dark"
                data-bs-toggle="collapse"
                href={`#collapse_${props.applianceUid}`}
                role="button"
                aria-expanded="false"
                aria-controls={`collapse_${props.applianceUid}`}
              >
                <i className="fas fa-caret-down"/>
              </a>
            </div>
            <div
              className="collapse shadow-sm mt-2"
              id={`collapse_${props.applianceUid}`}
            >
              <div className="card card-body py-1">
                <div className="row">
                  <small>Tác vụ nhanh sẽ hiển thị ở đây</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}
