import React, {useEffect, useState} from 'react'
import QrCamera from './QrCamera'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useAuthCheck} from "../../hooks/login-hook";

export default function QrIndex(props) {
  const [uid, setUid] = useState(undefined)
  const {paramUid} = useParams()
  const user = useSelector((state) => state.userLog.user)
  const navigate = useNavigate()
  const authCheck = useAuthCheck()

  useEffect(async () => {
    if (! await authCheck()) {
      navigate('/login?next=/qr')
      return
    }
  }, [])

  const callbackfn = (uid) => {
    setUid(uid)
    if (uid !== undefined) {
      navigate('/appliances/' + uid)
    }
  }

  const handleCancel = () => {
    setUid(undefined)
  }

  useEffect(async () => {
    try {
      setTimeout(() => callbackfn(paramUid), 1000)
    } catch (e) {
    }
  }, [])

  return (
    <>
      <div className="container mt-5">
        {user ? (
          <div>
            Xin chào, <b>{user.name}</b>
          </div>
        ) : (
          <div>
            <Link to="/login">Đăng nhập</Link> để mở khoá nhanh hơn.
          </div>
        )}
      </div>
      <br/>
      {props.accessedByLink ? (
        <div className="">
          <Link to="/qr">Mở camera</Link>
        </div>
      ) : (
        <QrCamera parentCallback={callbackfn}/>
      )}
      {/*<ApplianceController accessedByLink={props.accessedByLink} uid={uid} onCancel={handleCancel}/>*/}
    </>
  )
}
