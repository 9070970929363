import React from 'react'
import {useAlert} from "react-alert"
import autoCommandApi from "../../api/autoCommandApi";
import * as moment from "moment";

export default function AutoCommandItem(props) {
  const alert = useAlert()
  const ACTIONS = {
    1: 'MỞ KHÓA',
    3: 'BẬT',
    4: 'TẮT',
  }
  const DAYS_OF_WEEK = {
    1: 'Thứ 2',
    2: 'Thứ 3',
    3: 'Thứ 4',
    4: 'Thứ 5',
    5: 'Thứ 6',
    6: 'Thứ 7',
    7: 'Chủ Nhật'
  }

  const handleRemoveCommand = async (e, autoCommandId) => {
    e.preventDefault()
    const confirmRunning = window.confirm('Bạn có chắc chắn muốn xóa?.')
    if (!confirmRunning) return
    try {
      const response = await autoCommandApi.removeAutoCommand(props.appliance.uid, autoCommandId)
      if (response.success) {
        alert.success(response.message)
        props.refreshAutoCommand(autoCommandId, props.appliance.uid)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
    }
  }

  return (
    <div className="card mt-3 border-0" style={{backgroundColor: '#e8f4f8'}}>
      <div className="card-body">
        <div className="card-title d-flex justify-content-between">
          <span className="fs-6 text-nowrap fw-bold text-primary" data-bs-toggle="collapse"
                href={"#collapseAutoCommand-" + props.uid} role="button" aria-expanded="true"
                aria-controls={"collapseAutoCommand-" + props.uid}>
            <a className="badge bg-light text-dark"><i className="fas fa-caret-down"/></a>
            &nbsp; {props.appliance.name ?? 'Hẹn giờ'}
          </span>
          <small>{props.autoCommands.length} lệnh đang hoạt động</small>
        </div>

        <div className="collapse shadow-sm mt-2 show" id={"collapseAutoCommand-" + props.uid}>
          {props.autoCommands.map((autoCommand, index) => {
            return <div className="card card-body py-1 mt-3" key={index}>
              <div className="d-flex justify-content-between">
                <small>Hành động: <b>{ACTIONS[autoCommand.action]}</b></small>
                <small>Tạo bởi: <b>{autoCommand.created_by}</b></small>
                <small><a href="#" className={'text-danger'}
                          onClick={(e) => handleRemoveCommand(e, autoCommand.id)}>Xóa</a></small>
              </div>

              <hr/>
              <div className="row">
                {autoCommand.details.map((detail, detailIndex) => {
                  return <div className={'col-md-6'} key={'detail-' + index + '-' + detailIndex}>
                    <small>{moment(detail.time, 'HH:mm:ss').utcOffset('Asia/Ho_Chi_Minh').format('HH:mm')} - {DAYS_OF_WEEK[detail.day_of_week]} hằng
                      tuần</small>
                  </div>
                })}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  )
}
