import logo from './logo.svg'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import CreateJourney from './components/CreateJourney/CreateJourney'
import {HashRouter, Route, Routes} from 'react-router-dom'
import Navbar from './components/navbar'
import Login from './components/LoginRegister/Login/Login'
import Logout from './components/LoginRegister/Login/Logout'
import QrIndex from './components/Qr/QrIndex'
import Register from './components/LoginRegister/Login/Register';
import SmartAppliancesList from './components/appliances/SmartAppliancesList';
import NewAppliance from './components/add-new-appliance/NewAppliance';
import ApplianceInfomation from './components/smart-appliance-detail/ApplianceInfomation';
import Backup from "./components/sync/Backup";
import AutoCommand from "./components/auto-command/AutoCommand";

function App() {

  return (
    <div className="container-fluid p-0">
      <div className="App" >
        <Navbar/>
        <Routes>
          <Route path={'/newjourney'} element={<CreateJourney/>}/>
          <Route exact path="/" element={<SmartAppliancesList/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/logout" element={<Logout/>}/>
          <Route exact path="/register" element={<Register/>}/>
          <Route exact path="/qr" element={<QrIndex/>}/>
          <Route exact path="/appliances" element={<SmartAppliancesList/>}/>
          <Route exact path="/appliances/:uid" element={<ApplianceInfomation/>}/>
          <Route exact path="/appliances/new" element={<NewAppliance/>}/>
          <Route exact path="/backup" element={<Backup/>}/>
          <Route exact path="/auto-command" element={<AutoCommand/>}/>

          <Route
            exact
            path="/get-appliance/:paramUid"
            element={<QrIndex accessedByLink={true}/>}
          />
        </Routes>
      </div>
    </div>
  )
}

export default App
