import React, {useState} from 'react'
import {Offcanvas} from 'react-bootstrap'
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
import Collapse from 'react-bootstrap/Collapse';
import * as _ from 'lodash'
import * as moment from "moment";
import autoCommandApi from "../../api/autoCommandApi";

export default function AddAutoCommandPopup(props) {

  const [isLoading, setIsLoading] = useState(false)
  const [changeableTypes, setChangeableTypes] = useState([])
  const [selectedMode, setSelectedMode] = useState(0)
  const [notification, setNotification] = useState('')
  const [collapseDayOfWeek, setCollapseDayOfWeek] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const alert = useAlert();
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false
  })
  const [selectedTime, setSelectedTime] = useState('')
  const [selectedAction, setSelectedAction] = useState('')
  const [selectedUid, setSelectedUid] = useState(props.selectedUid ?? '')

  const DAYS_OF_WEEK = {
    1: 'Thứ 2',
    2: 'Thứ 3',
    3: 'Thứ 4',
    4: 'Thứ 5',
    5: 'Thứ 6',
    6: 'Thứ 7',
    7: 'Chủ Nhật'
  }

  const [validationFail, setValidationFail] = useState({
    selected_time: false,
    selected_days_of_week: false,
    selected_uid: false,
    selected_action: false,
  })

  const handleClose = () => {
    props.toggleModal()
    setChangeableTypes([])
    setNotification('')
  }

  const handleShow = async (e) => {
  }

  const handleSelectADayOfWeek = (e) => {
    setSelectedDaysOfWeek(old => ({...old, [e.target.value]: e.target.checked}))
  }

  const handleSelectTime = (e) => {
    setSelectedTime(e.target.value)
  }

  const handleSelectAction = (e) => {
    setSelectedAction(e.target.value)
  }

  const handleSelectUid = (e) => {
    setSelectedUid(e.target.value)
  }

  const handleChangeBtn = async () => {
    try {
      setIsLoading(true)
      setValidationFail({
        selected_time: false,
        selected_days_of_week: false,
        selected_uid: false,
        selected_action: false,
      })
      let notification = '';
      if (_.isEmpty(selectedTime)) {
        setValidationFail(old => ({...old, selected_time: true}))
        notification = 'Dữ liệu không hợp lệ.'
      }
      if (_.isEmpty(selectedAction)) {
        setValidationFail(old => ({...old, selected_action: true}))
        notification = 'Dữ liệu không hợp lệ.'
      }
      if (_.isEmpty(selectedUid)) {
        setValidationFail(old => ({...old, selected_uid: true}))
        notification = 'Dữ liệu không hợp lệ.'
      }
      if (Object.entries(selectedDaysOfWeek).filter(([id, selected]) => selected).length === 0) {
        setValidationFail(old => ({...old, selected_days_of_week: true}))
        notification = 'Dữ liệu không hợp lệ.'
      }
      setNotification(notification)
      if (!_.isEmpty(notification)) {
        return
      }
      const dataSend = {
        uid: selectedUid,
        action: Number(selectedAction),
        time: moment(selectedTime, 'HH:mm').utcOffset('Asia/Ho_Chi_Minh').format('HH:mm:ss'),
        days_of_week: Object.entries(selectedDaysOfWeek).filter(([day, selected]) => selected).map(([day, selected]) => Number(day))
      }
      const response = await autoCommandApi.addAutoCommand(dataSend)
      if (response.success) {
        alert.success(response.message + '. Vui lòng tải lại trang.')
        handleClose()
      } else {
        alert.error(response.message)
        setError(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
      setError(e.response.data.message)
    } finally {
      setIsLoading(false)
      setTimeout(() => setError(''), 4000)
    }
  }

  return (
    <>
      <style type="text/css">
        {`
        .offcanvas {
        height: auto
        }
        `}
      </style>

      <Offcanvas show={props.open} onShow={handleShow} backdrop={true} placement="bottom" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className={'text-danger'}>Thêm hẹn giờ thiết bị</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className={validationFail.selected_uid === true ? 'text-danger col-5' : 'col-5'}>Chọn thiết bị</div>
            <div className="col-7">
              <select className="form-select" onChange={handleSelectUid} aria-label="Default select example">
                <option>Chọn một</option>
                {props.appliances.map(appliance => (
                  <option key={appliance.uid} value={appliance.uid} >{appliance.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className={validationFail.selected_action === true ? 'text-danger col-5' : 'col-5 '}>Chọn hành động
            </div>
            <div className="col-7">
              <select className="form-select" onChange={handleSelectAction} aria-label="Default select example">
                <option>Chọn</option>
                <option className={'text-success'} value="3">Bật</option>
                <option className={'text-danger'} value="4">Tắt</option>
              </select>
            </div>
          </div>
          <div className="row mt-2">
            <div className={validationFail.selected_time === true ? 'text-danger col-5' : 'col-5'}>Chọn thời gian</div>
            <div className="col-7">
              <input type="time" onChange={handleSelectTime} className="form-control"/>
            </div>
          </div>
          <div className="row mt-2">
            <div className={validationFail.selected_days_of_week ? 'text-danger col-5 ' : 'col-5 '}>Chọn ngày</div>
            <div className="col-7">
              <div type="text" onClick={() => setCollapseDayOfWeek(old => !old)} className={'form-control'}>
                {Object.entries(selectedDaysOfWeek).filter(([id, selected]) => selected).length === 0 && 'Chọn'}
                {Object.entries(selectedDaysOfWeek).filter(([id, selected]) => selected).length === 7 && 'Hằng ngày'}
                {Object.entries(selectedDaysOfWeek).filter(([id, selected]) => selected).length < 7
                && Object.entries(selectedDaysOfWeek).filter(([id, selected]) => selected).length > 0
                && Object.entries(selectedDaysOfWeek).filter(([id, selected]) => selected).map(([id, selected]) => (
                  selected && <span key={id}>Mỗi {DAYS_OF_WEEK[Number(id)]}, </span>
                ))}
              < /div>
            </div>
          </div>
          <Collapse in={collapseDayOfWeek} className={'mt-2'}>
            <div className="card card-body">
              {Object.entries(DAYS_OF_WEEK).map(([dayId, alias]) => (
                <div className="form-check" key={dayId}>
                  <input className="form-check-input" onChange={handleSelectADayOfWeek} type="checkbox" value={dayId}
                         id={"day-of-week-" + dayId}/>
                  <label className="form-check-label" htmlFor={"day-of-week-" + dayId}>
                    Mỗi {alias}
                  </label>
                </div>
              ))}
            </div>
          </Collapse>
          {notification &&
          <div>
            {notification}
          </div>}

          <div className='mb-2'>
            <div className='my-3'>
              <button onClick={handleChangeBtn} disabled={isLoading} className="btn btn-danger btn-block w-100 mt-3">
                {isLoading && <i className="fas fa-spinner fa-pulse"/>}
                Hẹn giờ
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
