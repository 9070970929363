import React, {useState} from 'react'
import {Badge, Button, Carousel, Form, InputGroup, Modal} from 'react-bootstrap'
import directApi from "../../api/directApi";
import {useAlert} from "react-alert";

export default function ConnectToAppliance(props) {
  const [index, setIndex] = useState(0);
  const handleClose = () => props.toggleModal()
  const [sendLoading, setSendLoading] = useState(false)
  const [sendError, setSendError] = useState('')
  const alert = useAlert()

  const handleNext = (selectedIndex, e) => {
    if (index < 1) {
      setIndex((old) => old + 1);
    } else {

    }
  };

  const handleShow = () => {
    setIndex(0)
  }

  const handlePrev = (selectedIndex, e) => {
    if (index > 0) {
      setIndex((old) => old - 1);
    }
  };

  const handleSendData = async () => {
    try {
      const confirmRuning = window.confirm('Hãy chắc chắn đèn AP của thiết bị đã sáng.')
      if (!confirmRuning) return
      setSendLoading(true)
      setSendError('')
      directApi.import(props.connectionConfigs, props.appliance);
      setTimeout(() => {
        setSendLoading(false)
        props.toggleModal()
        props.toggleWaitModal()
      }, 500)
    } catch (e) {
      alert.error('Đã xẩy ra lỗi khi gửi đến thiết bị.')
      setSendLoading(false)
    }
  }

  const moveToNextStep = () => {
    props.toggleModal()
    props.toggleWaitModal()
  }

  return (
    <>
      <Modal
        show={props.open}
        onHide={handleClose}
        onShow={handleShow}
        backdrop="static"
        keyboard={false}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Hoàn tất cài đặt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel slide={false} activeIndex={index} controls={false} indicators={false}>
              <Carousel.Item>
                <h6><i className="fa-solid fa-wifi"/> Cung cấp thông tin WIFI</h6>
                <small>Thiết bị sẽ dùng WIFI này để kết nối Internet.</small>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon3">
                    Tên WIFI
                  </InputGroup.Text>
                  <Form.Control required onChange={(event) => {
                    props.connectionConfigs.wifi_ssid = event.target.value
                  }} id="basic-url" aria-describedby="basic-addon3"/>
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="wifi-pass-text">
                    Mật khẩu WIFI
                  </InputGroup.Text>
                  <Form.Control onChange={(event) => {
                    props.connectionConfigs.wifi_password = event.target.value
                  }} aria-describedby="wifi-pass-text"/>
                </InputGroup>
              </Carousel.Item>
              <Carousel.Item>
                <h6 className='text-primary'><i className="fa-solid fa-hand-point-up"/> 1. Chuyển chế độ AP</h6>
                <small>- Nhấn nút AP trên thiết bị và chờ 10 giây.</small>
                <hr/>
                <h6 className='text-primary'><i className="fa-solid fa-wifi"/> 2. Truy cập WIFI AP</h6>
                <div><small>- Vào cài đặt WIFI trên điện thoại của bạn</small></div>
                <div><small>- Tìm và kết nối vào WIFI có dạng SM-xxx</small></div>
                <div><small>- Nếu được yêu cầu nhập mật khẩu, hãy nhập 12345678</small></div>
                <hr/>
                <h6 className='text-primary'><i className="fa-solid fa-file-export"/> 3. Nạp dữ liệu</h6>
                <div><small>- Sau khi kết nối được vào WIFI AP, hãy bấm nút <Badge bg="primary">Tiến hành</Badge> phía
                  dưới để nạp dữ liệu cho thiết bị.</small></div>
              </Carousel.Item>
            </Carousel>
            <hr/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlePrev}>
              Trở lại
            </Button>
            {index < 1 ?
              <Button variant="primary" onClick={handleNext}>
                Tiếp theo
              </Button>
              :
              <Button variant="primary" onClick={handleSendData} disabled={sendLoading}>
                {sendLoading && <i className="fas fa-spinner fa-pulse"/>} Tiến hành
              </Button>
            }
            {sendError && <small className='text-danger'>{sendError}</small>}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
