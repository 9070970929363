import axiosClient from './axiosClient'
import Cookies from 'js-cookie';
import JWT from 'expo-jwt';
import * as _ from "lodash";

const applianceApi = {
  getAppliance: (params) => {
    const url = `/get-appliance/` + params
    return axiosClient.get(url)
  },
  sendCommandE2ee: (uid, phoneNumber, command) => {
    const url = 'command/send-command-e2ee'
    const token = Cookies.get('access_token')
    let pin = Cookies.get('pin-' + phoneNumber + '-' + uid)
    if (!_.isEmpty(pin)) {
      try {
        pin = JSON.parse(pin)
      } catch (e) {
        alert.error('Vui lòng đồng bộ lại mã PIN.')
        return
      }
    }

    if (pin.code === undefined) {
      return alert.error('Không có PIN của thiết bị trên máy của bạn. Vui lòng sao lưu mã PIN trước.')
    }
    return axiosClient.post(url, {
      uid: uid,
      command: JWT.encode(command, pin.code)
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  },
  sendCommand: (uid, action) => {
    const url = 'command/send-command'
    const token = Cookies.get('access_token')

    return axiosClient.post(url, {
      uid: uid,
      action: action
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  },

  getAppliances: () => {
    const url = '/appliance/get-appliances'
    const token = Cookies.get('access_token')

    return axiosClient.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
  },
  getDetail: (applianceUid) => {
    const url = '/appliance/get-detail'
    const token = Cookies.get('access_token')

    return axiosClient.get(url, {
      params: {
        uid: applianceUid
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  },
  setName: (uid, newName) => {
    const url = 'appliance/set-name'
    const token = Cookies.get('access_token')
    return axiosClient.post(url,
      {
        uid: uid,
        name: newName
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        }
      })
  },
  changeStatus: (uid, status) => {
    const url = 'appliance/set-status'
    const token = Cookies.get('access_token')
    return axiosClient.post(url,
      {
        uid: uid,
        status: status
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        }
      })
  },
  getPinsSavedOnServer: (uid, status) => {
    const url = 'permission/get-pins-saved-on-server'
    const token = Cookies.get('access_token')
    return axiosClient.get(url,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        }
      })
  },
  syncPins: (pinsNeedSyncing) => {
    const url = 'permission/sync-pins'
    const token = Cookies.get('access_token')
    return axiosClient.post(url,
      {pins_need_syncing: pinsNeedSyncing},
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        }
      })
  },
  checkRegisterLog: (uid) => {
    const url = 'appliance/check-register-log'
    const token = Cookies.get('access_token')
    return axiosClient.get(url, {
      params: {
        uid: uid,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  },
  getCurrentTypeAndChangeableTypes: (uid) => {
    const url = '/appliance/get-current-type-and-changeable-types'
    const token = Cookies.get('access_token')

    return axiosClient.get(url, {
      params: {
        uid: uid
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  },
  setType: (uid, mode) => {
    const url = 'appliance/set-type'
    const token = Cookies.get('access_token')
    return axiosClient.post(url,
      {
        uid: uid,
        type: mode,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        }
      })
  },
  getSocketChannelInfo: (uid) => {
    const url = '/appliance/get-socket-channel-info'
    const token = Cookies.get('access_token')

    return axiosClient.get(url, {
      params: {
        uid: uid
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      },
    })
  }
}

export default applianceApi
