const directApi = {
  import: (connection, appliance) => {
    const data = 'serial=' + encodeURIComponent(appliance.serial)
    + '&wifi_ssid=' + encodeURIComponent(connection.wifi_ssid)
    + '&wifi_password=' + encodeURIComponent(connection.wifi_password)
    + '&mqtt_account=' + encodeURIComponent(connection.mqtt_account)
    + '&mqtt_username=' + encodeURIComponent(connection.mqtt_username)
    + '&mqtt_password=' + encodeURIComponent(connection.mqtt_password)
    + '&mqtt_address=' + encodeURIComponent(connection.mqtt_address)
    + '&mqtt_port=' + encodeURIComponent(connection.mqtt_port)
    + '&mqtt_topic=' + encodeURIComponent(connection.mqtt_topic)
    + '&timezone=' + encodeURIComponent('+7')
    + '&pin=' + encodeURIComponent(appliance.pin)

    const url = 'http://192.168.4.1/import?' + data
    window.open(url, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30");
  },
  sendCommand: (ip, command) => {
    const data = 'command=' + encodeURIComponent(command)

    const url = `http://${ip}/send-command?` + data
    window.open(url, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30");
  }
}


export default directApi
