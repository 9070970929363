import React, {useEffect, useState} from 'react'
import {Button, Carousel, Form, InputGroup, Modal} from 'react-bootstrap'
import cryptoRandomString from 'crypto-random-string'

export default function BeforeRegistration(props) {
  const [show, setShow] = useState(false)
  const [isE2ee, setIsE2ee] = useState(false)
  const [index, setIndex] = useState(0);

  const handleClose = () => {
    setIndex(0)
    props.toggleModal()
  }
  const [pinCode, setPinCode] = useState(() => {
    const pinCode = cryptoRandomString({length: 16, type: 'alphanumeric'})
    props.appliance.pin = pinCode
    return pinCode
  })

  useEffect(() => {
    if (props.open) {
      setIndex(0)
    }
  }, [props.open])

  const handleNext = (selectedIndex, e) => {
    if (index < 1) {
      setIndex((old) => old + 1);
    } else {

    }
  };

  const handlePrev = (selectedIndex, e) => {
    if (index > 0) {
      setIndex((old) => old - 1);
    }
  };

  const handleSendData = (e) => {
    props.appliance.pin = pinCode;
    props.appliance.is_e2ee = isE2ee
    props.toggleModal()
    props.handleRegister(e)
  }

  const changePinCode = () => {
    setPinCode(cryptoRandomString({length: 16, type: 'alphanumeric'}))
  }

  return (
    <>
      <Modal
        show={props.open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Hoàn tất cài đặt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Carousel slide={false} activeIndex={index} controls={false} indicators={false}>
              <Carousel.Item>
                <h6><i className="fa-solid fa-tag"/> Đặt tên cho thiết bị</h6>
                <small>Ví dụ: Khóa cổng, Khóa phòng khách,...</small>
                <InputGroup className="mb-3">
                  <Form.Control aria-required={true} onChange={(event) => {
                    props.appliance.name = event.target.value
                  }} id="basic-url" aria-describedby="basic-addon3"/>
                </InputGroup>
              </Carousel.Item>
              <Carousel.Item>
                <h6><i className="fa-solid fa-lock"/> Thiết lập kết nối an toàn</h6>
                <Form.Group className="">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Lưu trữ mã PIN an toàn trên đám mây."
                    checked={!isE2ee}
                    onChange={() => setIsE2ee(!isE2ee)}
                  />
                </Form.Group>
                {isE2ee === true &&
                <>
                  <div className='px-4'>
                    <div className="text-success">
                      <small><i className="fa-solid fa-shield"/> Lưu mã PIN trên điện thoại. (e2ee)</small>
                    </div>
                    <div className="text-danger">
                      <small><i className="fa-solid fa-exclamation-circle"/> Ủy quyền mở khóa: Không khả dụng.</small>
                    </div>
                    <div className="text-danger">
                      <small><i className="fa-solid fa-exclamation-circle"/> Cần đồng bộ khi đăng nhập ở thiết bị
                        khác</small>
                    </div>
                  </div>
                  <Form.Group className="my-3">
                    <div>
                      <small>Mã PIN đễ mã hóa khi truyền dữ liệu. <span
                        className='text-danger'> Hãy bảo mật!</span></small>
                    </div>
                    <InputGroup className="mt-2 mb-3">
                      <InputGroup.Text id="wifi-pass-text">
                        Mã PIN
                      </InputGroup.Text>
                      <Form.Control id="wifi-password" readOnly={true}
                                    value={pinCode}
                                    aria-describedby="wifi-pass-text"/>
                      <InputGroup.Text onClick={changePinCode}><i
                        className="fa-solid fa-rotate-right"/></InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </>
                }
              </Carousel.Item>
            </Carousel>
            <hr/>
          </Modal.Body>
          <Modal.Footer>
            {index > 0 &&
            <Button variant="secondary" onClick={handlePrev}>
              Trở lại
            </Button>
            }
            {index < 1 ?
              <Button variant="primary" onClick={handleNext}>
                Tiếp theo
              </Button>
              :
              <Button variant="primary" onClick={handleSendData}>
                Tiến hành
              </Button>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
