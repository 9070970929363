import React, {useRef, useState} from 'react'
import {Offcanvas} from 'react-bootstrap'
import PasswordInput from "../LoginRegister/Login/PasswordInput";
import {useAlert} from "react-alert";
import permissionApi from "../../api/permissionApi";
import {useNavigate} from "react-router-dom";

export default function DeleteConfirmationPopup(props) {

  const handleClose = () => props.toggleModal()
  const passwordStartRef = useRef(null)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const alert = useAlert();
  const handleShow = (e) => {
    passwordStartRef.current.focus()
  }

  const handleDeleteBtn = async () => {
    try {
      if (password.length < 6 || isNaN(password)) {
        alert.error('Mật khẩu phải có 6 số.')
        return
      }
      const response = await permissionApi.unregister(props.uid, password)
      if (response.success) {
        alert.success(response.message)
        props.leaveChannel()
        navigate('/appliances')
      } else {
        alert.error(response.message)
        props.setAppliance()
        setError(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
      setError(e.response.data.message)
    } finally {
      setTimeout(() => setError(''), 4000)
    }
  }

  return (
    <>
      <style type="text/css">
        {`
        .offcanvas {
        height: auto
        }
        `}
      </style>

      <Offcanvas
        show={props.open}
        onShow={handleShow}
        backdrop={true}
        placement="bottom"
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className={'text-danger'}>{'Xác nhận xóa thiết bị'}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div><small className='fw-bold'>Bạn đang xóa sở hữu thiết bị này. Hãy lưu ý:</small></div>
          <div><small>- Bạn và thành viên sẽ không thể điều khiển thiết bị này.</small></div>
          <div><small>- Các cài đặt hiện tại của thiết bị sẽ bị mất.</small></div>
          <div><small>- Dữ liệu lịch sử của thiết bị sẽ bị mất.</small></div>

          {error &&
          <div className={'my-2'}>
            <div className="alert alert-warning"><small>{error}</small></div>
          </div>}
          <div className='mb-2'>
            <div className='text-danger mt-5 mb-3'><small>Nhập mật khẩu để tiếp tục:</small></div>
            <PasswordInput
              ref={passwordStartRef}
              sendPasswordToParent={(password) => {
                setPassword(password)
              }}
            />
            <div className='my-2'>
              <button onClick={handleDeleteBtn} className="btn btn-danger btn-block w-100">Xóa thiết bị</button>
            </div>
          </div>


        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
