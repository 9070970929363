import axiosClient from './axiosClient'
import Cookies from 'js-cookie'

const autoCommandApi = {
  getCommandsAllAppliances: () => {
    const url = '/command/get-commands-all-appliances'
    const token = Cookies.get('access_token')

    return axiosClient.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
    })
  },
  getCommands: (uid) => {
    const url = '/command/get-commands'
    const token = Cookies.get('access_token')

    return axiosClient.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      },
      params: {
        uid: uid,
      },
    })
  },
  addAutoCommand: (data) => {
    const url = 'command/add-auto-command'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
  removeAutoCommand: (uid, autoCommandId) => {
    const url = 'command/remove-auto-command'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, {
      uid: uid,
      auto_command_id: Number(autoCommandId)
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
      }
    })
  },
  setCountdownAutoCommand: (data) => {
    const url = 'command/set-countdown-auto-command'
    const token = Cookies.get('access_token')
    return axiosClient.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token, //the token is a variable which holds the token
      }
    })
  },
}


export default autoCommandApi
