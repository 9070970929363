import React from 'react'
import {Link} from 'react-router-dom'
import {Container, Nav, Navbar, Offcanvas} from 'react-bootstrap'
import {useSelector} from 'react-redux'
import {AndroidView, IOSView} from "react-device-detect";

export default function _Navbar(props) {
  const user = useSelector((state) => state.userLog.user)

  return (
    <>
      <Navbar bg="light" fixed="top" collapseOnSelect expand="xs" className='my-0'>
        <Container fluid>
          <Navbar.Brand className="d-flex flex-row align-items-center">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              <img
                src={require('../datas/images/onkey_logo.png')}
                width="40"
                height="40"
                className="d-inline-block align-text-top"
              />
              <img
                src={require('../datas/images/onkey_branchname.png')}
                className="d-inline-block align-text-top"
                width="90"
              />
            </Link>
            &emsp;

            <AndroidView>
              <a
                  href="/mobile-app/android/chiakhoathongminh_v1.apk"
                  className="d-flex flex-column"
                  style={{fontSize: '7px', textDecoration: 'none', color: 'black'}}
              >
                <span className={'text-center'}>Tải ứng dụng cho điện thoại: </span>
                <img
                    style={{opacity: '70%'}}
                    src={require('../datas/images/android_download.png')}
                    className="d-inline-block align-text-top"
                    width="90"
                />
              </a>
            </AndroidView>
            <IOSView>
              <a
                  href="/mobile-app/android/chiakhoathongminh_v1.apk"
                  className="d-flex flex-column"
                  style={{fontSize: '7px', textDecoration: 'none', color: 'black'}}
              >
                <span className={'text-center'}>Tải ứng dụng cho điện thoại: </span>
                <img
                    style={{opacity: '70%'}}
                    src={require('../datas/images/download-ios-app.png')}
                    className="d-inline-block align-text-top"
                    width="90"
                />
              </a>

            </IOSView>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar"/>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            style={{width: '80%'}}
            backdrop={true}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <img
                  src={require('../datas/images/onkey_logo.png')}
                  width="40"
                  height="40"
                  className="d-inline-block align-text-top"
                />
                &nbsp; &nbsp;
                <img
                  src={require('../datas/images/onkey_branchname.png')}
                  className="d-inline-block align-text-top"
                  width="100"
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="me-auto">
                <Nav.Link as={Link} eventKey="/" to="/">
                  Trang chủ
                </Nav.Link>
                <Nav.Link as={Link} eventKey="/qr" to="/qr">
                  Mở khoá bằng QR
                </Nav.Link>
                <hr/>
                {user && (
                  <>
                    <Nav.Link
                      as={Link}
                      className=""
                      eventKey="/appliances"
                      to="/appliances"
                    >
                      Thiết bị của tôi
                    </Nav.Link>
                    <Nav.Link as={Link} className="" eventKey="/backup" to="/backup">
                      Sao lưu và đồng bộ
                    </Nav.Link>
                    <Nav.Link as={Link} className="" eventKey="/backup" to="/auto-command">
                      Cài đặt hẹn giờ thiết bị
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      className=""
                      eventKey="/group-management"
                      to="/group-management"
                    >
                      Quản lý nhóm và thành viên
                    </Nav.Link>
                    <hr/>
                  </>
                )}

                {user ? (
                  <Nav.Link
                    as={Link}
                    className="text-danger"
                    eventKey="/logout"
                    to="/logout"
                  >
                    Đăng xuất
                  </Nav.Link>
                ) : (
                  <Nav.Link as={Link} eventKey="/login" to="/login">
                    Đăng ký/ Đăng nhập
                  </Nav.Link>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <div className="align-navbar" style={{height: '100px'}}>
        .
      </div>
    </>
  )
}
