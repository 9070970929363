import React, {useRef, useState} from 'react'
import {Button, Carousel, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import Echo from "laravel-echo";
import Cookies from "js-cookie";
import applianceApi from "../../api/applianceApi";

const token = Cookies.get('access_token')
window.Pusher = require("pusher-js");
const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_PUSHER_KEY,
  cluster: "ap1",
  forceTLS: true,
  auth: {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  },
  authEndpoint: process.env.REACT_APP_API_URL + '/api/broadcasting/auth',
  reconnectDelay: 1
});

export default function WaitForPairing(props) {
  const [index, setIndex] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false)
  const intervalRef = useRef(null)
  const [isTooLong, setIsTooLong] = useState(false)
  const navigate = useNavigate()

  const handleShow = async (e) => {
    if (props.open === false) return
    setIsSuccess(false)
    intervalRef.current = setInterval(async () => {
      if (!isSuccess) {
        setIsTooLong(true)
        const response = await applianceApi.checkRegisterLog(props.connectionConfigs.uid)
        if (response.data.register_status === true) {
          setIsSuccess(true)
          setTimeout(async () => {
            clearInterval(intervalRef.current)
            intervalRef.current = null
            await handleClose()
            navigate('/appliances')
          }, 4000)
        }
      }
    }, 25000)

    try {
      // Kết nối đến Boardcast
      var channel = await echo.private('appliance-register-signal.' + (props.connectionConfigs.uid ?? '').toLowerCase())
      channel.listen("FirstConnectionSignalFromAppliance", function (log) {
        setIsSuccess(true)
        setTimeout(async () => {
          clearInterval(intervalRef.current)
          intervalRef.current = null
          await handleClose()
          navigate('/appliances')
        }, 4000)
      });
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    }
  }

  const handleBackToHomescreen = () => {
    handleClose()
    navigate('/appliances')
  }

  const handleClose = () => {
    props.toggleModal()
    clearInterval(intervalRef.current)
    intervalRef.current = null
    setIsSuccess(false)
    setIsTooLong(false)
    echo.leave('private-appliance-register-signal.' + (props.appliance.uid ?? '').toLowerCase())
  }

  return (
    <>
      <Modal show={props.open} onShow={handleShow} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Đang đợi kết nối...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel slide={false} activeIndex={index}>
            <Carousel.Item>
              <h6><i className="fa-solid fa-wifi"/> Hoàn tất truyền dữ liệu cho thiết bị.</h6>
              <div><small>Thiết bị đang tiến hành kết nối Internet...</small></div>
              {isSuccess ?
                <div className={'text-center text-success'}>
                  <h1><i className="fa-solid fa-circle-check fa-beat"/></h1>
                  <h6>Kết nối thành công</h6>
                  <div><small>Quay lại trang chủ sau 3 giây.</small></div>
                </div>
                : <h1 className={'my-5 text-center'}>
                  <i className="fas fa-spinner fa-pulse"/>
                </h1>
              }
              {isTooLong &&
              <div>
                <hr/>
                <small className='text-warning'><i className="fa-solid fa-circle-exclamation"/> Nếu bạn vẫn chưa kết nối
                  được? Có thể do tên hoặc mật khẩu WIFI chưa chính xác. Hãy thử lại với một WIFI khác.</small>
              </div>}
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          {isTooLong &&
          <Button variant="secondary" onClick={handleClose}>
            Thử lại
          </Button>}
          <Button variant="primary" onClick={handleBackToHomescreen}>
            Về trang chủ.
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
