import axiosClient from './axiosClient'
import Cookies from "js-cookie";
import * as _ from 'lodash'

const loginApi = {
  login: (creditials) => {
    const url = 'auth/login'
    return axiosClient.post(url, creditials)
  },
  getUser: () => {
    const url = 'auth/user'
    const token = Cookies.get('access_token')
    if (_.isEmpty(token)) {
      return null
    }
    return axiosClient.get(url, {
        headers: {
          Authorization: 'Bearer ' + token, //the token is a variable which holds the token
        },
      })
  },
  register: (creditials) => {
      const url = 'auth/register'
      return axiosClient.post(url, creditials)
  }
}
export default loginApi
