import React, {useEffect, useState} from 'react'
import * as _ from "lodash";
import applianceApi from "../../api/applianceApi";
import JWT from "expo-jwt";
import directApi from "../../api/directApi";
import {useAlert} from "react-alert";
import autoCommandApi from "../../api/autoCommandApi";
import {Collapse} from "react-bootstrap";
import {v4 as uuidv4} from 'uuid';
import moment from "moment";

export default function ApplianceCommand(props) {
  const [isCommandSending, setIsCommandSending] = useState(false)
  const [switchStatus, setSwitchStatus] = useState(0)
  const [hourPeriod, setHourPeriod] = useState(0)
  const [minutePeriod, setMinutePeriod] = useState(0)
  const [timeToTurnOff, setTimeToTurnOff] = useState('')
  const [countdownAutoCommandId, setCountdownAutoCommandId] = useState(null)
  const [showCollapseAdvanceCommand, setShowCollapseAdvanceCommand] = useState(false)
  const [countdownMode, setCountdownMode] = useState(false)
  const [requestUuid, setRequestUuid] = useState('')
  const [timeoutId, setTimeoutId] = useState(-1)

  const alert = useAlert();
  const modeLAN = 'LAN';
  const modeInternet = 'INTERNET';
  const OPEN = 1;
  const STT_ON = 1;
  const STT_OFF = 0;
  const TURN_ON = 3;
  const TURN_OFF = 4;
  useEffect(() => {
    if (!_.isEmpty(props.countdownAutoCommand)) {
      setTimeToTurnOff(moment(props.countdownAutoCommand.details[0].date_and_time).format('HH:mm:00'))
      setCountdownAutoCommandId(props.countdownAutoCommand.id)
    }
  }, [props.countdownAutoCommand])

  useEffect(() => {
    if (props.appliance.type === 3) {
      setSwitchStatus(props.switchStatus)
    }
  }, [props.switchStatus])

  useEffect(() => {
    if ([1, 2].includes(props.permission.role) && !_.isEmpty(props.appliance.uid)) {
      let countdownFromStorage = localStorage.getItem(`countdown-${props.appliance.uid}`) ?? JSON.stringify({})
      try {
        countdownFromStorage = JSON.parse(countdownFromStorage)
      } catch (e) {
      }
      setCountdownMode(countdownFromStorage.countdown_mode ?? false)
      setMinutePeriod(countdownFromStorage.minute_period ?? 0)
      setHourPeriod(countdownFromStorage.hour_period ?? 0)
    }
  }, [props.appliance.uid, props.permission.role])

  // Thực hiện khi request gửi đi thành công
  useEffect(() => {
    if (props.requestUuid === requestUuid && (props.requestUuid !== '' || requestUuid !== '')) {
      setIsCommandSending(false)
      clearTimeout(timeoutId)
    }
  }, [props.requestUuid])

  const handleSendCommand = async (action) => {
    if (!isCommandSending) {
      if (props.networkMode === modeLAN) {
        setIsCommandSending(true)
        handleSendCommandViaLAN(action)
        return
      }
      if (props.appliance.is_e2ee === 1) {
        setIsCommandSending(true)
        await handleSendCommandE2ee(action)
      }
      if (props.appliance.is_e2ee === 0) {
        if (switchStatus === STT_OFF && countdownMode === true && minutePeriod === 0 && hourPeriod === 0) {
          alert.error('Khoảng thời gian hẹn giờ không hợp lệ')
          return
        }
        if ([1, 2].includes(props.permission.role)) {
          storeCountdownConfigs()
        }
        setIsCommandSending(true)
        await handleSendCommandNormal(action)
      }
    }
    const timeoutId = setTimeout(() => {
      setIsCommandSending(false)
      if (props.appliance.type === 3) {
        const changeSwitchStatus = window.confirm('Có lỗi xẩy ra khi gửi yêu cầu đến thiết bị. Bạn có muốn chuyển trạng thái bật/tắt mà không thực hiện lệnh?')
        if (changeSwitchStatus) {
          setSwitchStatus(old => old === 1 ? 0 : 1)
        }
      }
    }, 15000);
    setTimeoutId(timeoutId)
  }

  const handleSendCommandNormal = async (action) => {
    try {
      const response = await applianceApi.sendCommand(props.appliance.uid, action)
      if (response.success) {
        if (countdownMode && switchStatus === STT_OFF && props.networkMode === modeInternet) {
          await sendCountdownAutoCommand()
        }
        return setRequestUuid(response.data.request_uuid)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error('Có lỗi xẩy ra: ' + e.response.data.message)
    }
  }

  const handleSendCommandE2ee = async (action) => {
    try {
      if (props.pinCode === undefined) {
        alert.error('Không có PIN của thiết bị trên máy của bạn. Vui lòng sao lưu mã PIN trước.')
        return
      }
      const unlockCommand = {
        action: action,
        exp: Math.floor(new Date().getTime() / 1000) + 60,
        phone_number: props.user.phone_number,
        request_uuid: uuidv4(),
      }
      const response = await applianceApi.sendCommandE2ee(props.appliance.uid, props.user.phone_number, unlockCommand)
      if (response.success) {
        setRequestUuid(response.data.request_uuid)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error('Có lỗi xẩy ra: ' + e.response.data.message)
    }
  }

  const handleSendCommandViaLAN = (action) => {
    const command = {
      action: action,
      phone_number: props.user.phone_number,
      exp: Math.floor(new Date().getTime() / 1000) + 60
    }
    const encodedCommand = JWT.encode(command, props.appliancePassword)
    directApi.sendCommand(props.networkIP, encodedCommand)
    setSwitchStatus(old => old === 1 ? 0 : 1)
  }

  const handleChangeCountdownMode = (action) => {
    setCountdownMode(old => !old)
  }

  const sendCountdownAutoCommand = async () => {
    const dataSend = {
      uid: props.appliance.uid,
      minute_period: Number(minutePeriod),
      hour_period: Number(hourPeriod),
    }
    const response = await autoCommandApi.setCountdownAutoCommand(dataSend)
    if (response.success) {
      setTimeToTurnOff(moment(response.data.details[0].date_and_time).format('HH:mm:00'))
      setCountdownAutoCommandId(response.data.id)
    } else {
      alert.error(response.message)
    }
  }

  const handleChangeHourPeriod = (e) => {
    setCountdownMode(true)
    if (e.target.value < 0 || e.target.value > 23) {
      setHourPeriod(old => old)
    } else {
      setHourPeriod(e.target.value)
    }
  }

  const handleChangeMinutePeriod = (e) => {
    setCountdownMode(true)
    if (e.target.value < 0 || e.target.value > 59) {
      setMinutePeriod(old => old)
    } else {
      setMinutePeriod(e.target.value)
    }
  }

  const storeCountdownConfigs = () => {
    localStorage.setItem(`countdown-${props.appliance.uid}`, JSON.stringify({
      countdown_mode: countdownMode,
      minute_period: Number(minutePeriod),
      hour_period: Number(hourPeriod),
    }))
  }

  const handleRemoveCountdownAutoCommand = async (e) => {
    e.preventDefault()
    const confirmRunning = window.confirm('Bạn có chắc chắn muốn xóa?.')
    if (!confirmRunning) return
    try {
      const response = await autoCommandApi.removeAutoCommand(props.appliance.uid, countdownAutoCommandId)
      if (response.success) {
        alert.success(response.message)
        setTimeToTurnOff(null)
        setCountdownAutoCommandId(null)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.response.data.message)
    }
  }

  return (
    <>
      {props.user === undefined || props.user === null ?
        <div className='text-center text-danger my-3'>
          <small>Đăng nhập để điều khiển</small>
        </div> :
        <>
          {_.isEmpty(props.pinCode) && props.appliance.is_e2ee && props.networkMode === modeInternet ?
            <div className='text-center text-danger my-3'>
              <small>Không có mã PIN lưu trên thiết bị này. <br/> Vui lòng đồng bộ từ Server để điều khiển.</small>
            </div> :
            <>
              {props.appliance.type === 1 &&
              <div className="card mt-3 shadow border-1" style={{backgroundColor: '#e6ffe6'}}>
                <div className="card-body">
                  <div className="card-title d-flex justify-content-center align-items-center"
                       onClick={() => handleSendCommand(OPEN)}>
                    {!isCommandSending ?
                      <img src={require('../../datas/images/onkeylogo.gif')} alt="Hãy" width={'40px'}
                           height={'40px'}/>
                      : <i className="fas fa-spinner fa-pulse my-3"/>}
                    <span className="fs-6 text-nowrap fw-bold text-success">
                        &nbsp;NHẤN ĐỂ MỞ KHOÁ
                      {props.networkMode === modeLAN &&
                      <div><small className={'fw-lighter'}>&nbsp;LOCAL IP: {props.networkIP}</small></div>}
                      </span>
                  </div>
                </div>
              </div>}
              {props.appliance.type === 3 &&
              <div className="card mt-3 shadow"
                   style={{backgroundColor: ( switchStatus === 0 && '#e6ffe6') || ( switchStatus === 1 && '#FFCCCB') || ( switchStatus === null && '#D3D3D3')}}>
                <div className="row g-0">
                  <div className="col-1">
                    {switchStatus === 1 ?
                    <span className="badge bg-light text-success">
                      <i className="fa-regular fa-circle-dot fa-beat-fade"/> &emsp; ĐANG BẬT
                    </span>
                    :
                    <span className="badge bg-light text-danger">
                      <i className="fa-regular fa-circle-dot fa-beat-fade"/> &emsp; ĐANG TẮT
                    </span>
                    }
                  </div>
                  <div className="col-10">
                    <div className="card-body">
                      <div className="card-title  d-flex justify-content-center align-items-center"
                           onClick={() => {
                              switchStatus === STT_OFF ? handleSendCommand(TURN_ON) : handleSendCommand(TURN_OFF)
                           }}>
                        {!isCommandSending ?
                          <img src={require('../../datas/images/onkeylogo.gif')} alt="Hãy" width={'40px'}
                               height={'40px'}/>
                          : <i className="fas fa-spinner fa-pulse my-3"/>}
                        <div>
                          { switchStatus === 0 &&
                          <span className="fs-6 text-nowrap fw-bold text-success">&nbsp; NHẤN ĐỂ BẬT</span>}
                          { switchStatus === 1 &&
                          <span className="fs-6 text-nowrap fw-bold text-danger">&nbsp; NHẤN ĐỂ TẮT</span>}
                          {props.networkMode === modeLAN &&
                          <div><small className={'fw-lighter'}>&ensp;LOCAL IP: {props.networkIP}</small></div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  { props.appliance.type === 3  && [1, 2].includes(props.permission.role) &&
                  <div className="col-1 d-flex align-items-end" style={{marginLeft: '-50px'}}>
                    <span className="badge bg-light text-success" onClick={() => setShowCollapseAdvanceCommand(old => !old)}>
                      Nâng cao <i className="fa-solid fa-angles-down"/>
                    </span>
                  </div>}
                </div>
                {props.networkMode === modeInternet && [1, 2].includes(props.permission.role) && switchStatus === 0 &&
                <Collapse in={showCollapseAdvanceCommand || countdownMode} className={"card-body py-1 bg-light rounded m-0"}>
                  <div className="card-title">
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="countdownTimer" checked={countdownMode}
                             onChange={handleChangeCountdownMode}/>
                      <label className="form-check-label" htmlFor="countdownTimer">Tự động tắt sau&nbsp;</label>
                      <input type="number" value={hourPeriod} onChange={handleChangeHourPeriod}
                             style={{width: '45px', textAlign: 'center', border: 'none', borderBottom: '1px dashed'}}/>
                      &nbsp;
                      giờ
                      <input type="number" value={minutePeriod} onChange={handleChangeMinutePeriod}
                             style={{width: '45px', textAlign: 'center', border: 'none', borderBottom: '1px dashed'}}/>
                      &nbsp;
                      phút
                    </div>
                  </div>
                </Collapse>}
                {props.networkMode === modeInternet && [1, 2].includes(props.permission.role) && switchStatus === 1 && _.isEmpty(timeToTurnOff) &&
                  <Collapse in={showCollapseAdvanceCommand || countdownMode} className={"card-body py-1 bg-light rounded m-0"}>
                    <div className="card-title">
                      <div className="d-flex justify-content-between">
                      <span>
                        <label className="form-check-label" htmlFor="countdownTimer">Đặt lịch tắt sau&nbsp;</label>
                        <input type="number" value={hourPeriod} onChange={handleChangeHourPeriod}
                               style={{width: '45px', textAlign: 'center', border: 'none', borderBottom: '1px dashed'}}/>
                        &nbsp;
                        giờ
                        <input type="number" value={minutePeriod} onChange={handleChangeMinutePeriod}
                               style={{width: '45px', textAlign: 'center', border: 'none', borderBottom: '1px dashed'}}/>
                        &nbsp;
                        phút
                      </span>
                        <span>
                        <button className="btn btn-outline-success btn-sm py-0" onClick={sendCountdownAutoCommand}>Đặt</button>
                      </span>
                      </div>
                    </div>
                  </Collapse>}
                {switchStatus === STT_ON && props.networkMode === modeInternet && !_.isEmpty(timeToTurnOff) &&
                <div className="card-body py-1 px-0 bg-light rounded">
                  <div className="card-title">
                    <div className={'text-center'}>
                      <small>Chế độ tự động tắt đang hoạt động. Tắt lúc <b>{timeToTurnOff}</b></small>
                      &nbsp;
                      &nbsp;
                      <i onClick={handleRemoveCountdownAutoCommand} className="fa-regular fa-circle-xmark text-danger"/>
                    </div>
                  </div>
                </div>}
              </div>}
            </>}
        </>
      }
    </>
  )
}
