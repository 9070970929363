import React, {useEffect, useState} from 'react'
import SmartApplianceItem from './SmartApplianceItem'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import applianceApi from '../../api/applianceApi'
import {useAlert} from "react-alert";
import {useAuthCheck} from "../../hooks/login-hook";
import * as _ from 'lodash'

export default function SmartAppliancesList(props) {
  const user = useSelector((state) => state.userLog.user)
  const navigate = useNavigate()
  const [appliances, setAppliances] = useState([])
  const alert = useAlert()
  const authCheck = useAuthCheck();

  useEffect(async (applianceUid) => {
    try {
      if (!await authCheck()) {
        navigate('/login?next=/appliances')
        return
      }
      const response = await applianceApi.getAppliances()
      if (response.success) {
        setAppliances(response.data)
      } else {
        alert.error(response.message)
      }
    } catch (e) {
      alert.error(e.toString() + e.response.data.message)
    }
  }, [])

  return (
    <>
      <div className="container" style={{maxWidth: '500px'}}>
        <div className="row mb-3 align-items-center">
          <div className="col-6">
            <h5 className="">Thiết bị của tôi</h5>
          </div>
          <div className="col-6">
            <AddingBtn/>
          </div>
        </div>

        {_.isEmpty(appliances[1]) &&
        <div className={'my-1 text-secondary'}><small>Danh sách trống</small></div>}
        {appliances[1] && appliances[1].map((appliance) => {
          return (
            <SmartApplianceItem
              key={appliance.uid}
              applianceName={appliance.name}
              applianceAddress={'#' + appliance.uid.substr(-12)}
              applianceUid={appliance.uid}
              applianceStatus={appliance.status}
            />
          )
        })}

        <h5 className="my-4">Thiết bị được uỷ quyền</h5>
        {_.isEmpty(appliances[2]) &&
        <div className={'my-1 text-secondary'}><small>Danh sách trống</small></div>}
        {appliances[2] && appliances[2].map((appliance) => {
          return (
            <SmartApplianceItem
              key={appliance.uid}
              applianceName={appliance.name}
              applianceAddress={'#' + appliance.uid.substr(-12)}
              applianceUid={appliance.uid}
              applianceStatus={appliance.status}
            />
          )
        })}

        <h5 className="my-4">Thiết bị mà tôi là khách</h5>
        {_.isEmpty(appliances[3]) &&
        <div className={'my-1 text-secondary'}><small>Danh sách trống</small></div>}
        {appliances[3] && appliances[3].map((appliance) => {
          return (
            <SmartApplianceItem
              key={appliance.uid}
              applianceName={appliance.name}
              applianceAddress={'#' + appliance.uid.substr(-12)}
              applianceUid={appliance.uid}
              applianceStatus={appliance.status}
            />
          )
        })}
      </div>
    </>
  )
}

const AddingBtn = () => {
  return (
    <Box
      sx={{
        '& > :not(style)': {m: 1},
      }}
    >
      <Fab
        color="primary"
        to="/appliances/new"
        component={Link}
        size="small"
        aria-label="add"
        variant="extended"
      >
        <AddIcon sx={{mr: 1}}/>
        Thiết bị mới
      </Fab>
    </Box>
  )
}
