import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'
import {Provider} from 'react-redux'
import {HashRouter, BrowserRouter, Route, Routes} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  offset: '50px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <HashRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <Provider store={store}>
            <App/>
          </Provider>
        </AlertProvider>
      </HashRouter>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
