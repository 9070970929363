import React, {useRef} from 'react'
import {QrReader} from 'react-qr-reader'
import {useTorchLight} from '@blackbox-vision/use-torch-light'

export default function QrCamera(props) {
  const streamRef = useRef(null)
  const [on, toggle] = useTorchLight(streamRef.current)
  const setRef = ({ stream }) => (streamRef.current = stream)

  const handleResult = (result) => {
    if (result?.text) {
      const uidStartAt = (result?.text).indexOf('appliances/') + 11
      const uid = (result?.text).substring(uidStartAt)
      props.parentCallback(uid)
    }
  }
  return (
    <>
      <QrReader
        onLoad={setRef}
        onResult={(result, error) => {
          if (!!result) {
            handleResult(result)
          }

          if (!!error) {
          }
        }}
        constraints={{ facingMode: 'environment' }}
        scanDelay={500}
      />
      <div className="container" style={{maxWidth: '500px'}}>
        <div width="90%">
          <div className="d-flex justify-content-between">
            <div className="btn btn-primary" onClick={toggle}>
              <i className="fa-solid fa-lightbulb"/> {on ? 'ON' : 'OFF'}
            </div>
            <div className="btn btn-primary">
              <i className="fa-solid fa-camera-rotate"/>
            </div>
          </div>
          <br />
          <div className="">
            <b>Hướng dẫn: </b> Di chuyển camera vào mã QR trên thiết bị.
          </div>
        </div>
      </div>
    </>
  )
}
